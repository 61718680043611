<template>
    <div>
        <div :key="posterRefreshKey">
            <div class="gallery-video-group" v-if="item.mediaType == 1">
                <v-img
                    v-if="showPoster"
                    @error="handlePosterError(item)"
                    :src="handleThumbnail(item.url)"
                    :lazy-src="handleThumbnail(item.url)"
                    class="image-base"
                    cover
                    height="150px"
                    width="200px"
                    eager
                    :id="`photo-${item.id}`"
                ></v-img>

                <div v-else class="gallery-video-wrapper">
                    <video class="gallery-video" :src="item.url"></video>
                </div>
                <v-chip class="px-2 video-tag" x-small dark color="#ab0658">VIDEO</v-chip>
            </div>
            <div v-else class="image-wrapper">
                <div v-if="brokenImgOverlay" class="broken-img-overlay d-flex justify-center align-center flex-column">
                    <font-awesome-icon icon="fa-solid fa-image-slash" style="font-size: 2rem"></font-awesome-icon>
                    <span class="text-center text-caption mt-3 px-8">This image could not be loaded</span>
                </div>
                <v-img
                    v-if="!brokenImgOverlay"
                    @error="handleImgLoadError"
                    :src="handleImgSrc(item.url)"
                    :lazy-src="handleImgSrc(item.url)"
                    class="image-base"
                    cover
                    height="150px"
                    width="200px"
                    eager
                    :id="`photo-${item.id}`"
                ></v-img>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            brokenImgOverlay: false,
            showPoster: true,
            posterRefreshKey: 0,
        };
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        handleImgSrc(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations

            //const imgKitBase = 'https://ik.imagekit.io/memoryshare/';

            if (url) {
                const imgPath = url.split('/tribute-photos/')[1];
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-250,h-250/' + imgPath;
            }
        },
        handleThumbnail(url) {
            if (url) {
                let filePath = this.extractFilePath(url);

                return `${process.env.VUE_APP_IMG_KIT_BASE}${filePath}/ik-thumbnail.jpg`;
            }
        },
        extractFilePath(url) {
            let filePath = url;

            if (url.includes('/tribute-photos/')) {
                filePath = url.split('/tribute-photos/')[1];
            } else if (url.includes('/original-tribute-photos/')) {
                filePath = url.split('/original-tribute-photos/')[1];
            }

            return filePath;
        },
        handleImgLoadError() {
            this.brokenImgOverlay = true;
        },
        handlePosterError(item) {
            this.showPoster = false;
            this.posterRefreshKey++;
        },
    },
};
</script>
<style lang="scss" scoped>
.image-wrapper {
    width: 200px;
    height: 150px;
    padding: 2px;
}

.broken-img-overlay {
    background-color: #f1f4f7;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.video-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
}

.image-base {
    z-index: 1;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.gallery-video {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
    width: 250px;
    height: 200px;
    background-color: #f1f4f7;
}

.gallery-video-group {
    width: 200px;
    height: 150px;
    object-fit: cover;
    position: relative;
    padding: 2px;
}

.gallery-video-wrapper {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    border: 2px solid red;
}
</style>
