<template>
    <div>
        <h5>Selected Theme</h5>
        <div>
            <TemplatePreview v-if="selectedTemplate" class="template-summary-item" :template="selectedTemplate" />
            <p v-else>No theme selected</p>
        </div>

        <v-divider></v-divider>
    </div>
</template>
<script>
import TemplatePreview from '@/components/ManageService/Tribute/TemplatePreview.vue';

export default {
    data() {
        return {};
    },
    components: {
        TemplatePreview,
    },
    computed: {
        selectedTemplate: {
            get() {
                if (this.$store.state.tributeVideo.selectedTemplates.length > 0) {
                    return this.$store.state.tributeVideo.selectedTemplates[0];
                }
                return null;
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.template-summary-item {
    width: 300px;
    cursor: default;
}
</style>
