import ApiService from './api.service';

const TributeSongService = token => {
    const api = ApiService.createInstance(token);

    return {
        getSongs(params, queryParams = {}) {
            let url = 'TributeVideoSong';

            const queryString = new URLSearchParams();

            // Add genre parameters to the query string
            if (queryParams.genre && queryParams.genre.length > 0) {
                queryParams.genre.forEach(genre => {
                    queryString.append('genre', genre);
                });
            }

            // Add keyword parameters to the query string
            if (queryParams.keyword && queryParams.keyword.length > 0) {
                queryParams.keyword.forEach(keyword => {
                    queryString.append('keyword', keyword);
                });
            }

            if (queryString.toString()) {
                url += `?${queryString.toString()}`;
            }

            return api.get(url, { params });
        },

        getSong(id) {
            return api.get(`TributeVideoSong/${id}`);
        },

        updateSong(id, data) {
            return api.put(`TributeVideoSong/${id}`, data);
        },

        deleteSong(id) {
            return ApiService.delete(`TributeVideoSong/${id}`);
        },

        getGenres() {
            return api.get('MusicGenre', { params: { pageSize: 100 } });
        },

        addGenre(songId, genreId) {
            return api.put(`TributeVideoSong/genre/add/${songId}/${genreId}`);
        },

        removeGenre(songId, genreId) {
            return api.put(`TributeVideoSong/genre/remove/${songId}/${genreId}`);
        },

        createGenre(data) {
            return api.post('MusicGenre', data);
        },

        updateGenre(id, data) {
            return api.put(`MusicGenre/${id}`, data);
        },

        deleteGenre(id) {
            return api.delete(`MusicGenre/${id}`);
        },

        getKeywords() {
            return api.get('MusicKeyword', { params: { pageSize: 100 } });
        },

        addKeyword(songId, keywordId) {
            return api.put(`TributeVideoSong/keyword/add/${songId}/${keywordId}`);
        },

        removeKeyword(songId, keywordId) {
            return api.put(`TributeVideoSong/keyword/remove/${songId}/${keywordId}`);
        },

        createKeyword(data) {
            return api.post('MusicKeyword', data);
        },

        updateKeyword(id, data) {
            return api.put(`MusicKeyword/${id}`, data);
        },

        deleteKeyword(id) {
            return api.delete(`MusicKeyword/${id}`);
        },

        toggleStaffFavorite(songId, val) {
            return api.post(`TributeVideoSong/toggle-staff-favorite/${songId}/${val}`);
        },

        updateSong(id, data) {
            return api.put(`TributeVideoSong/${id}`, data);
        },

        getCopyrightSafeSongs(tributeVideoId) {
            return api.get(`TributeVideo/selected-songs/${tributeVideoId}?copyrightSafe=true`);
        },

        updateSelectedSongs(tributeVideoId, data) {
            return api.post(`TributeVideo/selected-songs/${tributeVideoId}`, data);
        },

        updateSongArtwork(id, fileName) {
            console.log('UPDATING ARTWORK', fileName);
            return api.put(`TributeVideoSong/artwork/${id}`, JSON.stringify(fileName), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },

        deleteSongArtwork(id) {
            return api.put(`TributeVideoSong/artwork/${id}`, JSON.stringify(''), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },

        updateSongLicense(id, fileName) {
            return api.put(`TributeVideoSong/song-license/${id}`, JSON.stringify(fileName), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },

        deleteSongLicense(id) {
            return api.put(`TributeVideoSong/song-license/${id}`, JSON.stringify(''), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
    };
};

export default TributeSongService;
