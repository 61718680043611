import ApiService from './api.service';

const TributePhotoService = token => {
    const api = ApiService.createInstance(token);

    return {
        createPhotoBatch(eventId, photoBatchData, replaceMainPhoto, config = {}) {
            return api.post(`/TributeVideoPhoto/${eventId}?replaceMain=${replaceMainPhoto}`, photoBatchData, {
                ...config,
            });
        },
        deletePhoto(id) {
            return api.delete(`/TributeVideoPhoto/${id}`);
        },
        deletePhotoBatch(ids) {
            return api.put(`/TributeVideoPhoto/batch-delete`, ids);
        },
        deletePhotoByGuid(guid) {
            return api.delete(`/TributeVideoPhoto/unique-delete/${guid}`);
        },
        getCollectionDuplicates(serviceId) {
            return api.get(`/TributeVideoPhoto/find-collection-duplicates/${serviceId}`);
        },
        getCollectionSimilars(serviceId) {
            return api.get(`/TributeVideoPhoto/find-collection-similars/${serviceId}`);
        },
        getUploadUrl(eventId, file) {
            return api.get(`/TributeVideoPhoto/sas/${eventId}/${file.name}`);
        },
        updatePhotoFileName(id, fileName) {
            return api.put(`/TributeVideoPhoto/${id}`, JSON.stringify(fileName));
        },
        getRemainingAllowedUploads(id) {
            return api.get(`/TributeVideoPhoto/remaining-allowed-uploads/${id}`);
        },
    };
};

export default TributePhotoService;
