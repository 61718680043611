<template>
    <div>
        <div>
            <h4 class="text-center p-3">Tribute Video Summary</h4>
            <div v-if="showPreview && previewSource" style="margin-bottom: 50px">
                <h5 class="text-center ms-orange ma-3">Preview</h5>
                <div class="d-flex justify-center">
                    <div class="preview-player">
                        <creatomate-preview
                            :source="previewSource"
                            :modifications="previewMods"
                            ref="preview"
                        ></creatomate-preview>
                        <v-alert v-if="audioRenderRequired" type="info" text
                            >Your video contains slides with audio. Background music adjustments will be applied in the
                            final render but won't be shown in preview.
                        </v-alert>
                    </div>
                </div>
            </div>

            <div>
                <h5 class="text-center ms-orange ma-3">Photo & Music</h5>
                <div>
                    <strong>Slideshow ({{ totalPhotos }})</strong>
                    <span class="ml-2">{{ fullVideoDuration | durationTimeStamp }}</span>
                    <photo-summary
                        ref="photoSummary"
                        v-if="activeTab"
                        @changeTab="tab => $emit('changeTab', tab)"
                        @refresh-preview="generateTributeVideoPreview"
                    />
                </div>

                <div class="mt-8">
                    <strong>Music Playlist Selection ({{ selectedSongs.length }})</strong>
                    <span class="ml-2">{{ playlistDuration | durationTimeStamp }}</span>
                    <p v-if="Math.round(playlistDuration) > Math.round(fullVideoDuration)" style="color: #1877f2">
                        Note: Music is longer than the video and will fade out at the end.
                    </p>

                    <playlist-summary
                        v-if="activeTab && token"
                        @changeTab="tab => $emit('changeTab', tab)"
                        @refresh-preview="generateTributeVideoPreview"
                        @refresh-sync="getSelectedSongs(tributeVideo.id)"
                        :displayDurationWarning="displayPlaylistDurationWarning"
                        :itemBaseId="'playlist-summary'"
                        :token="token"
                    />
                </div>
            </div>

            <div style="margin-top: 50px">
                <h5 class="text-center ms-orange ma-3">Animations</h5>
                <video-settings
                    ref="videoSettings"
                    :loading="loading"
                    @update-render-settings="data => updateRenderSettings(data)"
                    @changeTab="tab => $emit('changeTab', tab)"
                />
            </div>

            <div class="text-center ma-8">
                <v-btn
                    :disabled="disableSubmit"
                    :dark="!disableSubmit"
                    color="#fb8c00"
                    depressed
                    @click="handleSubmit"
                    >{{ objectHasValue(render) ? 'Recreate Tribute Video' : 'Create Tribute Video' }}</v-btn
                >
            </div>
        </div>

        <v-dialog v-model="reRenderModal" max-width="400px">
            <v-card class="p-3">
                <h5>Recreate Render?</h5>
                <p>
                    This will delete the current version of this tribute video and cannot be undone, confirm to
                    continue.
                </p>

                <div class="pt-4 d-flex justify-space-between">
                    <v-btn :disabled="loading" depressed @click="reRenderModal = false">Cancel</v-btn>
                    <v-btn :disabled="loading" depressed @click="submitRerender(tributeVideo.id)" color="error"
                        >Confirm</v-btn
                    >
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="musicOptOutModal" max-width="400px">
            <v-card>
                <v-card-title>No music selected</v-card-title>
                <v-card-text
                    >Looks like you haven't selected any music! Continue creating tribute video without
                    music?</v-card-text
                >
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="musicOptOutModal = false" :disabled="loading">Cancel</v-btn>
                    <v-btn depressed :dark="!loading" color="orange" @click="confirmMusicOptOut" :disabled="loading"
                        >Continue</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import PlaylistSummary from '@/components/ManageService/Tribute/PlaylistSummary';
import PhotoSummary from '@/components/ManageService/Tribute/PhotoSummary';
import VideoSettings from '@/components/ManageService/Tribute/VideoSettings';
import CreatomatePreview from '@/components/Misc/CreatomatePreview.vue';
import { debounceV2 } from '@/utilities/debounce.js';
import { audioTsToVideoTs } from 'mux.js/cjs/utils/clock';
import tributeVideo from '../../../store/modules/tribute-video';

export default {
    data() {
        return {
            message: 'hi',
            title: '',
            reloadCount: 0,
            posterReloadCount: 0,
            loading: false,
            templateDuration: 0,
            reRenderModal: false,
            musicOptOut: false,
            musicOptOutModal: false,
            transitionDuration: 1,
            activeAlert: true,
            previewSource: '',
            previewMods: null,
            fullVideoDuration: 0,
        };
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
        devMode: {
            type: Boolean,
            required: false,
        },
        render: {
            type: Object,
            default: {},
        },
        activeTab: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        PlaylistSummary,
        PhotoSummary,
        VideoSettings,
        CreatomatePreview,
    },
    watch: {
        selectedTemplates() {
            this.updateTemplateDuration();

            this.debouncedPreviewRefresh();
        },
        selectedSongs() {
            this.debouncedPreviewRefresh();
        },
        activeTab(newVal) {
            if (newVal) {
                this.debouncedPreviewRefresh();
                this.refreshTributeVideoData();
            }
        },
    },
    computed: {
        showPreview() {
            return this.selectedTemplates.length > 0 && this.selectedPhotos.length > 0 && this.activeTab;
        },
        totalPhotos() {
            return this.$store.state.tributeVideo.totalPhotos;
        },
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        audioRenderRequired() {
            return this.$store.state.tributeVideo.audioRenderRequired;
        },
        // TODO: allow selected photos, ui in work
        // Temp setting all photos to be used as selected
        selectedPhotos() {
            return this.$store.state.tributeVideo.photos;
        },
        selectedSongs: {
            get() {
                return this.$store.state.tributeVideo.selectedSongs;
            },
        },
        selectedTemplates() {
            return this.$store.state.tributeVideo.selectedTemplates;
        },
        playlistDuration() {
            let duration = 0;
            this.selectedSongs.forEach(song => {
                duration += song.duration;
            });
            return duration;
        },
        disableSubmit() {
            if (this.loading) {
                return true;
            }

            if (this.render?.status == 1 || this.render?.status == 0) {
                return true;
            }

            if (this.selectedTemplates.length <= 0) {
                return true;
            }

            return false;
        },
        displayPlaylistDurationWarning() {
            if (this.selectedSongs.length <= 0) {
                return false;
            }

            return Math.round(this.playlistDuration) < Math.round(this.fullVideoDuration);
        },
    },
    filters: {
        //handles duration including float type
        durationTimeStamp(val) {
            var minutes = Math.floor(val / 60);
            var totalSeconds = val % 60;
            var seconds = Math.floor(totalSeconds);
            var milliseconds = Math.round((totalSeconds - seconds) * 100); // Adjust this if you want more precision

            // If milliseconds are 50 or more, round up the seconds
            if (milliseconds >= 50) {
                seconds += 1;
                if (seconds >= 60) {
                    seconds = 0;
                    minutes += 1;
                }
            }

            function str_pad_left(string, pad, length) {
                return (new Array(length + 1).join(pad) + string).slice(-length);
            }

            const finalTime = minutes + ':' + str_pad_left(seconds, '0', 2);
            // + '.' + str_pad_left(milliseconds, '0', 2);
            return finalTime;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', [
            'updateTributeVideoSelectedTemplates',
            'updateTributeVideoSelectedSongs',
            'updateTributeVideoSelectedPhotos',
            'updateTributeVideoPhotos',
            'updateTributeVideo',
        ]),
        updateRenderSettings(data) {
            if (data && this.tributeVideo.id) {
                this.loading = true;
                this.axiosInstance
                    .post(`/TributeVideo/render-settings/${this.tributeVideo.id}`, data)
                    .then(response => {
                        const creatomateJson = JSON.parse(response.data.renderJson.creatomateJson);

                        // const modifications = response.data.modifications;

                        this.previewSource = JSON.stringify(creatomateJson.source);
                        this.previewMods = JSON.stringify(creatomateJson.modifications);

                        this.fullVideoDuration = response.data.renderJson.duration;

                        if (response.data.renderJson.slideDuration > 0) {
                            this.setSlideDuration(response.data.renderJson.slideDuration);
                        }

                        if (response.data.renderJson.autoSlideDuration != null) {
                            this.setAutoSlideDuration(response.data.renderJson.autoSlideDuration);
                        }

                        if (response.data.renderJson.floatingImages != null) {
                            this.setFloatingImage(response.data.renderJson.floatingImages);
                        }

                        if (response.data.renderJson.selectedAnimation >= 0) {
                            this.setAnimation(response.data.renderJson.selectedAnimation);
                        }

                        if (response.data.renderJson.selectedTransition >= 0) {
                            this.setTransition(response.data.renderJson.selectedTransition);
                        }
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$emit('refresh-sync');
                    });
            }
        },
        objectHasValue(obj) {
            return Object.keys(obj).length > 0;
        },
        handleSubmit() {
            if (this.objectHasValue(this.render)) {
                this.reRenderModal = true;
            } else {
                this.submitTributeRender();
            }
        },
        confirmMusicOptOut() {
            this.musicOptOut = true;
            this.musicOptOutModal = false;
            this.submitTributeRender();
        },

        reRenderStep1() {
            this.reRenderModal = true;
        },
        deleteRender(id) {
            return this.axiosInstance
                .delete(`/TributeVideoRender/${id}`)
                .then(response => {
                    // this.submitTributeRender();
                })
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async submitRerender(id) {
            this.loading = true;
            await this.deleteRender(id);
            await this.submitTributeRender();
            this.reRenderModal = false;
        },
        getVideoFileDuration(url) {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.src = url;

                video.addEventListener('loadeddata', () => {
                    let duration = Math.floor(video.duration);
                    return resolve(duration);
                    // The duration variable now holds the duration (in seconds) of the audio clip
                });
                video.addEventListener('error', () => {
                    return reject(error);
                });
            });
        },
        createTributeRenderData() {
            var includeFloatingImages = false;

            if (this.$refs.videoSettings.floatingImages) {
                includeFloatingImages = this.$refs.videoSettings.floatingImages;
            }

            if (this.tributeVideo.totalPhotos < this.tributeVideo.minPhotos) {
                this.showSnackbar({
                    color: 'red',
                    message: `Please select ${this.tributeVideo.minPhotos} photos to continue`,
                });
                return;
            }

            if (this.selectedTemplates.length == 0) {
                this.showSnackbar({ color: 'red', message: `Please include an intro to continue` });
                return;
            }

            if (this.selectedSongs.length == 0 && !this.musicOptOut) {
                this.musicOptOutModal = true;
                return;
            }

            let musicPlaylist = this.selectedSongs.map(item => {
                const found = this.selectedSongs.find(x => x.id == item.id);
                return { id: item.id, order: this.selectedSongs.indexOf(found) };
            });
            let templatePlaylist = this.selectedTemplates.map(item => {
                const found = this.selectedTemplates.find(x => x.id == item.id);
                return { ...item, order: this.selectedTemplates.indexOf(found) };
            });

            let data = {
                tributeVideoId: this.tributeVideo.id,
                title: this.title,
                // selectedPhotos: photoIdList,
                selectedPhotos: [],
                selectedSongs: musicPlaylist ? musicPlaylist : null,
                selectedTemplates: templatePlaylist,
                slideDuration: this.$refs.videoSettings.slideDuration,
                selectedAnimations: [],
                selectedTransitions: [],
                includeFloatingImages: includeFloatingImages,
            };

            if (this.$refs.videoSettings.selectedAnimation != null) {
                data.selectedAnimations.push({
                    startIndex: null,
                    endIndex: null,
                    type: this.$refs.videoSettings.animationTypes[this.$refs.videoSettings.selectedAnimation].value,
                });
            }

            if (this.$refs.videoSettings.selectedTransition != null) {
                data.selectedTransitions.push({
                    startIndex: null,
                    endIndex: null,
                    type: this.$refs.videoSettings.transitionTypes[this.$refs.videoSettings.selectedTransition].value,
                });
            }

            return data;
        },
        submitTributeRender() {
            if (!this.tributeVideo.id) {
                this.showSnackbar({ message: 'Error submitting render', color: 'error' });
                return;
            }

            let data = {
                tributeId: this.tributeVideo.id,
            };

            this.loading = true;
            return this.axiosInstance
                .post(`/TributeVideoRender/`, data)
                .then(response => {
                    this.showSnackbar({ message: 'Tribute Video Uploaded. Please allow time for processing' });
                    this.$emit('render-started');
                })
                .catch(error => {
                    const errorMessage = error.response.data.Message;
                    if (errorMessage == 'Multipart render error') {
                        this.showSnackbar({
                            color: 'error',
                            message: 'Multipart render error, please try another template.',
                        });
                    } else {
                        this.showSnackbar({ color: 'error', message: 'Error creating tribute video' });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        generateTributeVideoPreview() {
            if (this.showPreview && this.tributeVideo) {
                this.loading = true;
                this.axiosInstance
                    .post(`/TributeVideoRender/generate-tribute-video-json/${this.tributeVideo.id}`)
                    .then(response => {
                        const creatomateJson = JSON.parse(response.data.creatomateJson);

                        this.previewSource = JSON.stringify(creatomateJson.source);
                        this.previewMods = JSON.stringify(creatomateJson.modifications);

                        this.fullVideoDuration = response.data.duration;

                        if (response.data.slideDuration > 0) {
                            this.setSlideDuration(response.data.slideDuration);
                        }

                        if (response.data.autoSlideDuration != null) {
                            this.setAutoSlideDuration(response.data.autoSlideDuration);
                        }

                        if (response.data.floatingImages != null) {
                            this.setFloatingImage(response.data.floatingImages);
                        }

                        if (response.data.selectedAnimation >= 0) {
                            this.setAnimation(response.data.selectedAnimation);
                        }

                        if (response.data.selectedTransition >= 0) {
                            this.setTransition(response.data.selectedTransition);
                        }
                    })
                    .catch(error => {
                        console.log(error, 'generate error');
                        if (error.response.data) {
                            const errorMessage = error.response.data.Message;
                            if (errorMessage == 'Multipart render error') {
                                this.showSnackbar({
                                    color: 'error',
                                    message: 'Multipart render error, please try another template.',
                                });
                            } else {
                                this.showSnackbar({ color: 'error', message: 'Error creating tribute video data' });
                            }
                        } else {
                            console.log(error, 'error');
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        updateTemplateDuration() {
            let duration = 0;
            if (this.selectedTemplates.length > 0) {
                const temp = this.selectedTemplates[0];
                if (temp.duration > 0) {
                    duration = temp.duration;
                } else if (temp.url) {
                    this.getVideoFileDuration(template.url).then(resp => {
                        duration = resp;
                    });
                }
            }

            this.templateDuration = duration;
        },
        setSlideDuration(val) {
            if (this.$refs.videoSettings) {
                this.$refs.videoSettings.slideDuration = val;
            }
        },
        setTransition(val) {
            if (this.$refs.videoSettings) {
                this.$refs.videoSettings.selectedTransition = val;
            }
        },
        setAnimation(val) {
            if (this.$refs.videoSettings) {
                this.$refs.videoSettings.selectedAnimation = val;
            }
        },
        setFloatingImage(val) {
            if (this.$refs.videoSettings) {
                this.$refs.videoSettings.floatingImages = val;
            }
        },
        setAutoSlideDuration(val) {
            if (this.$refs.videoSettings) {
                this.$refs.videoSettings.autoSlideDuration = val;
            }
        },
        getSelectedTemplates(id) {
            this.axiosInstance
                .get(`TributeVideo/selected-templates/${id}`)
                .then(resp => {
                    if (resp.data) {
                        this.updateTributeVideoSelectedTemplates(resp.data.templates);
                    }
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        getSelectedSongs(id) {
            this.axiosInstance
                .get(`TributeVideo/selected-songs/${id}`)
                .then(resp => {
                    if (resp.data) {
                        this.updateTributeVideoSelectedSongs(resp.data);
                    }
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        refreshTributeVideoData() {
            if (this.tributeVideo.id) {
                this.getSelectedTemplates(this.tributeVideo.id);
                this.getSelectedSongs(this.tributeVideo.id);
                if (this.$refs.photoSummary) {
                    this.$refs.photoSummary.refreshSummaryPhotos();
                }
            }
        },
    },
    created() {
        this.debouncedPreviewRefresh = debounceV2(this.generateTributeVideoPreview, 500);
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();

        this.refreshTributeVideoData();
        this.debouncedPreviewRefresh();

        this.updateTemplateDuration();
    },
};
</script>
<style lang="scss" scoped>
.rerender-stat {
    height: 100%;
    border-radius: 5px;
}
.timestamp {
    margin-bottom: 5px;
    font-size: 0.8rem;
}
.missing {
    color: #fc676b;
    border: 2px solid #fc676b;
    cursor: pointer;
}

.fulfilled {
    background-color: #1877f2;
    color: white;
    cursor: pointer;
}
.ms-orange {
    color: #ff530d;
}

.fixed-alert {
    position: fixed;
    top: 72px;
    opacity: 1;
    right: 8px;
    // left: 50%;
    // transform: translateX(-50%);
    z-index: 2;
    background-color: white;
    border-radius: 5px;

    transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.hidden-alert {
    opacity: 0;
    top: -100px;
}

.preview-player {
    max-width: 700px;
    width: 100%;
}
.preview-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #000000c6;
    max-width: 700px;
    aspect-ratio: 16/9;
}
</style>
