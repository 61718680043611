import ApiService from './api.service';

const ServicesService = token => {
    const api = ApiService.createInstance(token);

    return {
        create(data) {
            return api.post(`/Services`, data);
        },
    };
};

export default ServicesService;
