div
<template>
    <div>
        <v-row class="p-1">
            <v-col cols="12">
                <!-- <div class="my-3" style="display: flex; flex-direction: column">
                    <strong style="font-size: 1.2rem">Select a Theme</strong>
                    <span
                        >Select from our library of curated themes. You will be able to modify different settings.</span
                    >
                </div>
                <v-text-field
                    :loading="loading"
                    background-color="#f8f8f8"
                    solo
                    flat
                    v-model="search"
                    label="Search Themes"
                >
                    <template v-slot:append>
                        <font-awesome-icon icon="fa-regular fa-magnifying-glass"></font-awesome-icon>
                    </template>
                </v-text-field> -->
                <p class="text-right" style="font-size: 0.8rem; color: gray">
                    {{ templates.length >= 0 ? templates.length : 0 }} of
                    {{ total }}
                </p>
            </v-col>
        </v-row>

        <div class="gallery-container">
            <div class="grid">
                <div class="grid-item" v-for="template in templates" :key="template.id">
                    <TemplatePreview
                        :class="isSelected(template.id) ? 'selected' : ''"
                        @click="handleClick($event, template)"
                        :template="template"
                        :isSelected="isSelected(template.id)"
                        show-tags
                    />
                </div>
            </div>
            <p id="infinite-scroll-templates-target"></p>
        </div>

        <v-dialog v-model="showingPreview" max-width="1000px">
            <v-card class="p-3" style="max-width: 100%" v-if="tempTemplate">
                <v-row no-gutters>
                    <v-col cols="12">
                        <div class="d-flex justify-space-between flex-wrap mb-2">
                            <h3>Theme Customizations</h3>

                            <v-chip v-if="isSelected(tempTemplate.id)" class="pr-3" color="success" label>
                                <font-awesome-icon
                                    class="mr-2"
                                    style="font-size: 1rem"
                                    icon="fa-regular fa-circle-check"
                                ></font-awesome-icon>

                                <span> SELECTED </span>
                            </v-chip>
                        </div>
                    </v-col>

                    <!-- player / images -->
                    <v-col class="pa-2" :md="templateTextElements.length > 0 ? 9 : 12" cols="12">
                        <div class="player-section" style="width: 100%; max-width: 100%">
                            <creatomate-preview
                                v-if="showingPreview && tempTemplate && tempSource && tempTemplate.title != 'No Theme'"
                                :source="tempSource"
                                :modifications="JSON.stringify(tempMods)"
                                class="elevation-2"
                            ></creatomate-preview>
                            <div style="position: relative" v-if="tempTemplate.title == 'No Theme'">
                                <video class="empty-video"></video>
                            </div>
                        </div>

                        <div class="image-section mt-4 pa-1" style="display: flex; overflow: auto; gap: 12px">
                            <div v-for="(element, index) in templateImageElements" :key="index">
                                <custom-tooltip :tooltipProps="{ top: true }">
                                    <template v-slot:activator>
                                        <v-img
                                            :ref="`template-image-${refreshKey}`"
                                            cover
                                            class="preview-img"
                                            :src="handleDynamicImageSrc(element.value)"
                                            :lazy-src="handleDynamicImageSrc(element.value)"
                                            alt="Template Image"
                                            @click="handleTemplateImageClick(element, index)"
                                        />
                                    </template>

                                    <template v-slot:content>
                                        <span
                                            >{{ element.name ? splitCamelCase(element.name) : 'Image ' + (index + 1) }}
                                        </span>
                                    </template>
                                </custom-tooltip>
                            </div>
                        </div>
                    </v-col>

                    <!-- input col -->
                    <v-col v-if="templateTextElements.length > 0" class="pa-2" md="3" cols="12">
                        <div class="input-section">
                            <div>
                                <h5 class="ma-0">{{ tempTemplate.title }}</h5>
                                <p class="subtle-text">Modify this theme's options below</p>
                                <div>
                                    <google-fonts-drop-down
                                        class="font-drop-down"
                                        @font-changed="font => handleFontChange(font)"
                                        ref="fontDropdown"
                                    ></google-fonts-drop-down>
                                </div>
                                <v-divider></v-divider>
                                <v-form v-model="customizeFormValid">
                                    <div class="mb-3" v-for="(element, index) in templateTextElements" :key="index">
                                        <v-text-field
                                            dense
                                            outlined
                                            @input="updatePreviewMods"
                                            background-color="white"
                                            v-model="element.value"
                                            :label="splitCamelCase(element.name)"
                                        ></v-text-field>
                                    </div>
                                </v-form>
                            </div>
                            <div>
                                <!-- <v-btn
                                    class="mt-3"
                                    block
                                    @click="showDemo(tempTemplate)"
                                    depressed
                                    :disabled="allValuesMatchDefault"
                                    >Restore Defaults</v-btn
                                > -->
                                <v-btn
                                    v-if="!isSelected(tempTemplate.id)"
                                    class="mt-3"
                                    block
                                    color="#2096f3"
                                    @click="selectTemplate(tempTemplate, false)"
                                    :dark="customizeFormValid"
                                    depressed
                                    :disabled="!customizeFormValid"
                                >
                                    Select Theme
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import TributeMusicTable from '@/components/Tables/Admin/TributeMusicTable.vue';
import { templateDynamicElements } from '@/constants.js';
import { debounceV2 } from '@/utilities/debounce.js';
import draggable from 'vuedraggable';
import { mapActions } from 'vuex';
import CreatomatePreview from '@/components/Misc/CreatomatePreview.vue';
import GoogleFontsDropDown from '@/components/ui/GoogleFontsDropDown.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import moment from 'moment';
import TemplatePreview from '@/components/ManageService/Tribute/TemplatePreview.vue';

export default {
    data() {
        return {
            templateDynamicElements,
            templates: [],
            templateModal: false,
            editDemoModal: false,
            tempTemplate: null,
            tempMods: {},
            tempSource: '',
            requiredRules: [value => !!value || 'Required'],
            imageInputRules: [
                value => {
                    return this.isGUIDorImageURL(value) || 'Invalid input. Enter a GUID or a hosted image URL.';
                },
                value => !!value || 'Required',
            ],
            loading: false,
            reloadCount: 0,
            themeToggleSelection: 0,
            search: '',
            showingPreview: false,
            pageNumber: 0,
            pageSize: 24,
            total: 0,
            observer: null,
            customizeFormValid: false,
            wheelProp: false,
            testObj: null,
            selectedFont: null,
            activeFont: null,
            clickTimeout: null,
            toggleBlocked: false,
            hoverTimeout: null,
            hoveredVideo: null,
            clickCount: 0,
            refreshKey: 0,
        };
    },
    components: {
        TributeMusicTable,
        CreatomatePreview,
        draggable,
        GoogleFontsDropDown,
        CustomTooltip,
        TemplatePreview,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
        playlistRedirect: {
            type: Boolean,
            required: false,
        },
        parentTabIndex: {
            type: String,
            required: false,
        },
        token: {
            type: String,
            required: true,
        },
        activeTab: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        mainPhotoUrl() {
            return this.$store.state.tributeVideo.mainPhotoUrl;
        },
        selectedTemplates: {
            get() {
                return this.$store.state.tributeVideo.selectedTemplates;
            },
            set(value) {
                this.updateTributeVideoSelectedTemplates(value);
            },
        },
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },

        templateTextElements() {
            if (this.tempTemplate) {
                return this.tempTemplate.dynamicElements.filter(x => x.type == 0 && x.name != 'dash');
            }
            return [];
        },
        templateImageElements() {
            if (this.tempTemplate) {
                return this.tempTemplate.dynamicElements.filter(x => x.type == 1);
            }
            return [];
        },
        allValuesMatchDefault() {
            return this.tempTemplate.dynamicElements.every(obj => {
                if (obj.name === 'BirthYear' || obj.name === 'BirthDate') {
                    return obj.value === this.$moment(this.tributeVideo.birthDate).format('YYYY');
                } else if (obj.name == 'DeathYear' || obj.name == 'DeathDate') {
                    return obj.value === this.$moment(this.tributeVideo.deathDate).format('YYYY');
                } else if (obj.name == 'FullName') {
                    return obj.value === this.tributeVideo.firstName + ' ' + this.tributeVideo.lastName;
                } else if (obj.name == 'FirstName') {
                    return obj.value === this.tributeVideo.firstName;
                } else if (obj.name == 'LastName') {
                    return obj.value === this.tributeVideo.lastName;
                } else if (obj.name == 'MainPhoto' || obj.name == 'MainPicture') {
                    return obj.value === this.tributeVideo.mainPhotoUrl;
                } else {
                    return obj.value === obj.defaultValue;
                }
            });
        },
        allValuesMatchModifications() {
            const modifications = this.selectedTemplates[0]?.modifications || {};
            return this.tempTemplate.dynamicElements.every(obj => obj.value === modifications[obj.creatomateId]);
        },
    },
    watch: {
        activeTab(newVal) {
            if (newVal) {
                this.refreshSelectedTemplates();
            }
        },
        search: debounceV2(async function () {
            this.pageNumber = 0;

            let results = await this.getTemplates({
                search: this.search,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
            });

            if (results) {
                this.templates = results.data.templates;
                this.total = results.data.total;
            }
        }, 500),
        parentTabIndex() {
            if (this.$refs.musicTable) {
                this.$refs.musicTable.stopAllAudio();
            }

            if (this.playlistRedirect) {
                this.themeToggleSelection = 2;
            } else {
                this.themeToggleSelection = 0;
            }
        },
        themeToggleSelection() {
            if (this.$refs.musicTable) {
                this.$refs.musicTable.stopAllAudio();
            }
            this.stopAllVideo();
        },
        async showingPreview() {
            this.selectedFont == null;
            if (this.showingPreview) {
                if (this.selectedTemplates.length > 0 && this.tempTemplate.id == this.selectedTemplates[0].id) {
                    var intro = this.selectedTemplates[0];
                    if (intro.customFont?.family) {
                        this.selectedFont = await this.getFont(intro.customFont.family);
                    }
                }
            }
        },
        mainPhotoUrl(value) {
            if (this.templateImageElements) {
                let mainImageEl = this.templateImageElements.find(x => x.name.toLowerCase().includes('main'));

                if (mainImageEl) {
                    mainImageEl.value = value;
                    this.refreshKey++;
                    this.showSnackbar({ message: 'Main photo updated' });
                }
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', [
            'updateTributeVideo',
            'updateTributeVideoSelectedTemplates',
            'updateTributeVideoSelectedSongs',
        ]),
        handleTemplateImageClick(image) {
            if (image.name.toLowerCase().includes('main')) {
                this.$emit('template-customize-main-photo');
            }
        },
        handleClick(event, temp) {
            this.clickCount++;

            let found = this.selectedTemplates.find(x => x.id == temp.id);

            if (!found) this.selectTemplate(temp);

            if (this.clickCount == 2) {
                this.handleDoubleClick(temp);
            } else {
                this.clickTimeout = setTimeout(() => {
                    this.clickCount = 0;
                }, 500);
            }
        },
        async handleDoubleClick(temp) {
            this.showDemo(temp);
            this.clickCount = 0;
        },
        handleScrollEnd() {
            if (!this.loading) {
                this.loadMoreTemplates();
            }
        },
        updateWheelProp(bool) {
            this.$refs.templateScroll.ps.settings.wheelPropagation = bool;
            this.$refs.templateScroll.update();
        },
        splitCamelCase(str) {
            // Use a regular expression to match capital letters preceded by lowercase letters
            // and insert a space between them

            if (str) {
                return str.replace(/([a-z])([A-Z])/g, '$1 $2');
            } else {
                return '';
            }
        },
        isGUIDorImageURL(str) {
            const guidRegex = /^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/;
            const imageURLRegex = /^(http|https):\/\/.*\.(jpg|jpeg|png|gif)$/i;

            return guidRegex.test(str) || imageURLRegex.test(str);
        },
        isGUID(str) {
            // Regular expression pattern
            const regex = /^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/;

            // Test the string against the regex pattern
            return regex.test(str);
        },
        handleDynamicImageSrc(val) {
            if (this.isGUID(val)) {
                return `https://creatomate.com/files/assets/${val}.jpg`;
            } else {
                return val;
            }
        },
        getModsJson(array) {
            const dictionary = {};
            for (const { creatomateId, value } of array) {
                if (creatomateId && creatomateId !== '') {
                    dictionary[creatomateId] = value || '';
                }
            }
            return dictionary;
        },
        updatePreviewMods: debounceV2(async function () {
            if (this.tempTemplate) {
                this.tempMods = this.getModsJson(this.tempTemplate.dynamicElements);

                //Update saved mods if selected
                if (this.selectedTemplates.length > 0) {
                    if (this.selectedTemplates[0].id == this.tempTemplate.id) {
                        await this.saveTemplate(this.tempTemplate.dynamicElements);
                    }
                }
            }
        }, 1000),
        async showDemo(item) {
            this.tempTemplate = item;

            this.autoFillFields(this.tempTemplate);
            try {
                await this.refreshPreview(item);
                this.showingPreview = true;
            } catch (error) {
                this.showSnackbar({ message: 'Error loading template source', color: 'error' });
                return;
            }
        },
        getTemplateDefaultFont(template) {
            for (let i = 0; i < template.elements.length; i++) {
                var el = template.elements[i];

                if (el.type === 'text' && el.name && el.name.toLowerCase().includes('name')) {
                    // if (this.$refs.fontDropdown) {
                    //     this.$refs.fontDropdown.setFont(el.font_family);
                    // }
                    return el.font_family;
                }
            }
        },
        async refreshPreview(template) {
            var templateSource = await this.getTemplateSource(template.cosmosId);

            const fontsDropdown = this.$refs.fontDropdown;

            if (
                this.selectedTemplates.length > 0 &&
                this.selectedTemplates[0].id == template.id &&
                this.selectedFont?.family &&
                this.selectedTemplates[0].title != 'No Theme'
            ) {
                if (fontsDropdown) {
                    fontsDropdown.setFont(this.selectedFont.family);
                }

                this.injectCustomFont(this.selectedFont, templateSource);

                this.tempTemplate.customFont = {
                    family: this.selectedFont.family,
                    source: this.selectedFont.files.regular.replace('http:', 'https:'),
                    style: 'normal',
                    weight: 700,
                };
            } else if (this.selectedFont) {
                if (fontsDropdown) {
                    fontsDropdown.setFont(this.selectedFont.family);
                }
            } else {
                const defaultFont = this.getTemplateDefaultFont(templateSource);
                if (fontsDropdown) {
                    fontsDropdown.setFont(defaultFont);
                }
            }

            this.tempSource = JSON.stringify(templateSource);
            this.tempMods = this.getModsJson(template.dynamicElements);

            this.tempTemplate.source = this.tempSource;
        },
        injectCustomFont(selectedFont, templateSource) {
            let fontData = {
                family: selectedFont.family,
                source: selectedFont.files.regular.replace('http:', 'https:'),
                style: 'normal',
                weight: 700,
            };

            templateSource.fonts.push(fontData);

            for (let i = 0; i < templateSource.elements.length; i++) {
                var el = templateSource.elements[i];
                if (this.isValidCustomText(el)) {
                    el.font_family = selectedFont.family;
                }
            }
        },
        isValidCustomText(el) {
            const restrictedWords = ['birthdate', 'birthyear', 'deathdate', 'deathyear', 'dash'];
            return el.type === 'text' && el.name && !restrictedWords.includes(el.name.toLowerCase());
        },
        async handleFontChange(font) {
            this.selectedFont = font;

            await this.refreshPreview(this.tempTemplate);

            var currentTemplates = await this.getSelectedTemplates(this.tributeVideo.id);

            if (
                this.selectedTemplates.length > 0 &&
                this.selectedTemplates[0].id == this.tempTemplate.id &&
                currentTemplates.templates[0].source != this.tempSource
            ) {
                await this.selectTemplate(this.tempTemplate, false);
            }
        },
        async getSelectedTemplates(id) {
            return this.axiosInstance
                .get(`TributeVideo/selected-templates/${id}`)
                .then(resp => {
                    return resp.data;
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        async getTemplateSource(creatomateId) {
            return this.axiosInstance
                .get(`/TributeVideoTemplate/template-source/${creatomateId}`)
                .then(resp => {
                    return resp.data.source;
                })
                .catch(err => {
                    console.log(err, 'error');
                    this.showSnackbar({ message: 'Error laoding template source', color: 'error' });
                });
        },
        isSelected(id) {
            const found = this.selectedTemplates.find(x => x.id == id);
            if (found) {
                return true;
            } else {
                return false;
            }
        },
        stopAllVideo() {
            const videoList = document.getElementsByClassName('videoPlayer');
            //Stop all audio sources
            for (let i = 0; i < videoList.length; i++) {
                videoList[i].pause();
            }
        },
        copyImageSource(item) {
            navigator.clipboard.writeText(item.url);
            this.showSnackbar({ message: 'Image source copied to clipboard' });
        },
        async saveTemplate(fields) {
            let dictionary = this.getModsJson(fields.filter(x => x.value));

            let data = {
                id: this.tempTemplate.id,
                // cosmosId: this.tempTemplate.cosmosId,
                // title: this.tempTemplate.title,
                // url: this.tempTemplate.url,
                // duration: this.tempTemplate.duration,
                customFont: this.tempTemplate.customFont,
                source: this.tempTemplate.source,
                modifications: dictionary,
            };

            await this.saveSelectedTemplateJson(this.tributeVideo.id, data);
        },
        saveSelectedTemplateJson(id, json) {
            return this.axiosInstance
                .post(`TributeVideo/selected-templates/${id}`, json)
                .then(resp => {
                    this.updateTributeVideoSelectedTemplates(resp.data.templates);
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        getTextElementValue(obj) {
            const expr = obj.name.toLowerCase();
            switch (expr) {
                case 'firstname':
                    return this.tributeVideo.firstName;
                case 'lastname':
                    return this.tributeVideo.lastName;
                case 'fullname':
                    return this.tributeVideo.firstName + ' ' + this.tributeVideo.lastName;
                case 'lifespan':
                    return this.getYearSpan(this.tributeVideo.birthDate, this.tributeVideo.deathDate);
                case 'birthyear':
                    return this.$moment(this.tributeVideo.birthDate).format('YYYY');
                case 'birthdate':
                    return this.$moment(this.tributeVideo.birthDate).format('YYYY');
                case 'deathyear':
                    return this.$moment(this.tributeVideo.deathDate).format('YYYY');
                case 'deathdate':
                    return this.$moment(this.tributeVideo.deathDate).format('YYYY');
                case 'dash':
                    return '-';
                default:
                    // console.log(`No switch case found for ${expr}.`);
                    return obj.defaultValue;
            }
        },
        getYearSpan(startDate, endDate) {
            var first = this.$moment(startDate);
            var second = this.$moment(endDate);
            var duration = this.$moment.duration(second.diff(first));
            var years = duration.asYears();
            return Math.floor(years);
        },
        async autoFillFields(template) {
            var textFields = template.dynamicElements.filter(x => x.type == 0);
            var imageFields = template.dynamicElements.filter(x => x.type == 1);

            // TODO: add support for prefilling audio and video fields
            // var videoFields = this.tempTemplate.dynamicFields.filter(x => x.type == 2);
            // var audioFields = this.tempTemplate.dynamicFields.filter(x => x.type == 3);

            // Autofill template text inputs
            textFields.forEach(element => {
                if (element.name) {
                    element.value = this.getTextElementValue(element);
                } else {
                    element.value = element.defaultValue;
                }
            });

            // Autofill template image inputs
            imageFields.forEach(element => {
                if (element.name && element.name.toLowerCase().includes('main')) {
                    element.value = this.tributeVideo.mainPhotoUrl;
                } else {
                    element.value = element.defaultValue;
                }
            });
        },

        async toggleSelected(item) {
            const found = this.selectedTemplates.find(x => x.id == item.id);

            if (found) {
                const index = this.selectedTemplates.indexOf(found);
                this.selectedTemplates.splice(index, 1);
                await this.saveSelectedTemplateJson(this.tributeVideo.id, { id: 0 });
                this.updateTributeVideoSelectedTemplates([]);
            } else {
                await this.selectTemplate(item);

                var templateSource = await this.getTemplateSource(item.cosmosId);
                if (!templateSource) {
                    this.clearSelected();
                    this.showSnackbar({ message: 'Template unavailable at this time.', color: 'error' });
                    return;
                }
            }
            this.$emit('refresh-sync');
        },
        async clearSelected() {
            await this.saveSelectedTemplateJson(this.tributeVideo.id, { id: 0 });
            this.updateTributeVideoSelectedTemplates([]);
        },
        async selectTemplate(item, autofill = true) {
            if (!this.tributeVideo.mainPhotoUrl) {
                this.showSnackbar({ message: 'Cannot select an intro slide without a main photo for tribute' });
                return;
            }

            this.tempTemplate = item;

            if (autofill) {
                await this.autoFillFields(this.tempTemplate);
            }

            this.selectedTemplates = [this.tempTemplate];

            await this.saveTemplate(this.tempTemplate.dynamicElements);

            this.showSnackbar({ message: 'Theme updated' });
            this.$emit('refresh-sync');
        },
        getTemplates(params) {
            this.loading = true;
            return this.axiosInstance
                .get('/TributeVideoTemplate', {
                    params: params,
                })
                .then(response => {
                    return response;
                })
                .catch(error => {
                    console.log('error', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        refreshTemplate(data) {
            const index = this.templates.findIndex(x => x.id === data.id);

            if (index !== -1) {
                this.templates.splice(index, 1, {
                    ...this.templates[index],
                    duration: data.duration,
                    status: data.status,
                    title: data.title,
                    updateDate: data.updateDate,
                    url: data.url,
                });
            }
        },
        async loadMoreTemplates() {
            if (this.templates.length < this.total && !this.loading) {
                this.pageNumber++;
                let newResults = await this.getTemplates({
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                });

                if (newResults) {
                    let combined = this.templates.concat(newResults.data.templates);
                    this.templates = combined;
                }
                // this.$refs.templateScroll.update();
            }
        },
        initObserver() {
            let options = {
                root: null,
                rootMargin: '0px',
                treshold: 0.1,
            };

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !this.loading) {
                        this.loadMoreTemplates();
                    }
                });
            }, options);
            this.observer = observer;

            let target = document.querySelector('#infinite-scroll-templates-target');
            observer.observe(target);
        },
        getFont(family) {
            return this.axios
                .get(
                    `https://www.googleapis.com/webfonts/v1/webfonts?family=${family}&key=${process.env.VUE_APP_GOOGLE_FONTS_KEY}`,
                )
                .then(resp => {
                    if (resp.data.items.length > 0) {
                        return resp.data.items[0];
                    }
                })
                .catch(err => {
                    console.log(err, 'google font error');
                });
        },
        async refreshSelectedTemplates() {
            if (this.tributeVideo.id) {
                var selectedTemps = await this.getSelectedTemplates(this.tributeVideo.id);
                if (selectedTemps.templates) {
                    this.updateTributeVideoSelectedTemplates(selectedTemps.templates);
                }
            }
        },
    },
    sockets: {
        // NotifyMainPhotoReplace(data) {
        //     if (data.eventId == this.tributeVideo.eventId) {
        //         let mainPhotoTemplateElement = this.templateImageElements.find(x =>
        //             x.name.toLowerCase().includes('main'),
        //         );
        //         if (mainPhotoTemplateElement) {
        //             mainPhotoTemplateElement.value = this.tributeVideo.mainPhotoUrl;
        //             console.log(this.tributeVideo.mainPhotoUrl, 'tribute main photo');
        //             console.log(mainPhotoTemplateElement, 'main image element');
        //             // console.log(this.templateImageElements, 'image elements');
        //             // console.log('main replace at templates', data);
        //         }
        //     }
        // },
        NotifyTributeThemeUpdate(data) {
            if (data && data.tributeId && data.tributeId > 0 && data.tributeId == this.tributeVideo.id) {
                if (data?.template?.id > 0) {
                    this.updateTributeVideoSelectedTemplates([data.template]);
                } else {
                    this.updateTributeVideoSelectedTemplates([]);
                }
            }
        },
    },
    async mounted() {
        this.createAxiosInstance();
        let results = await this.getTemplates({
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
        });

        if (results) {
            this.templates = results.data.templates;
            this.total = results.data.total;

            //init observer after results push target off viewport
            setTimeout(() => {
                this.initObserver();
            }, 200);
        }

        this.refreshSelectedTemplates(this.tributeVideo.id);
    },
};
</script>
<style lang="scss" scoped>
.gallery-container {
    overflow: auto;
    height: 100%;
    max-height: 550px;
    container-type: inline-size;
    container-name: gallery;
}

.grid {
    cursor: pointer;
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(150px, min-content));
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    justify-items: start;
    grid-gap: 10px;
    padding-top: 5px;
}
.grid-item {
    max-width: 350px;
    width: 100%;
}

.input-section {
    background-color: #f8f8f8;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 8px;
}
.preview-img {
    height: 100px;
    width: 100px;
    border-radius: 5px;
}
.subtle-text {
    font-size: 0.8rem;
    color: #999;
}

.grid-item:hover video {
    // border: 2px solid red;
    transform: scale(1.02);
    transition: 0.5s;
}

.grid-item:hover .hidden-preview-btn {
    opacity: 1;
}

@container gallery (max-width:520px) {
    .grid {
        justify-items: center !important;
    }
}
</style>
