<template>
    <AppLayout :has-background="true" :contained="true">
        <!-- START: Service Header -->
        <div class="service-page-container">
            <section class="service-header-section">
                <h1 class="header">
                    <div class="mb-2" style="font-size: 30px">
                        <small v-if="$auth.role.includes('SuperAdmin')"
                            ><a
                                class="settings-link"
                                :href="`/homes/settings/${service.funeralHomeId}?tab=details`"
                                target="_blank"
                                >{{ service.funeralHomeName }}</a
                            ></small
                        >
                    </div>
                    <span v-if="service.firstName != null">Service for {{ pageTitle }}</span>

                    <span v-if="service.test" class="text-callout ml-3" style="line-height: 1.3rem">TEST</span>
                    <span
                        v-if="!hasValidEvents && service.funeralHomeName != null"
                        class="text-callout-incomplete ml-3"
                        style="line-height: 1.3rem"
                        >Incomplete</span
                    >
                </h1>

                <div id="btn-container" class="button-container">
                    <!-- Ecom FH Link -->
                    <!-- <v-tooltip
                        attach="#btn-container"
                        bottom
                        v-if="
                            service.displayShop &&
                            !service.test &&
                            (service.serviceState == 5 || service.serviceState == 4)
                        "
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="
                                    openShopTab(service.funeralHomeName);
                                    hideActiveElements();
                                "
                                v-bind="attrs"
                                v-on="on"
                                fab
                                small
                            >
                                <font-awesome-icon icon="fa-solid fa-compact-disc" style="font-size: 1.4rem" />
                            </v-btn>
                        </template>
                        <span>Order a DVD</span>
                    </v-tooltip> -->
                    <custom-tooltip
                        :tooltipProps="{ bottom: true }"
                        v-if="
                            service.displayShop &&
                            !service.test &&
                            allowedPurchaseServiceStates.includes(service.serviceState)
                        "
                    >
                        <template v-slot:activator>
                            <v-btn
                                @click="
                                    openShopTab(service.funeralHomeName, service.funeralHomeId, false);
                                    hideActiveElements();
                                "
                                fab
                                small
                            >
                                <font-awesome-icon icon="fa-solid fa-compact-disc" style="font-size: 1.4rem" />
                            </v-btn>
                        </template>

                        <template v-slot:content>
                            <span>Order a DVD</span>
                        </template>
                    </custom-tooltip>

                    <!-- Ecom Family Link -->
                    <!-- <v-tooltip
                        attach=""
                        bottom
                        v-if="
                            service.displayShop &&
                            !service.test &&
                            (service.serviceState == 5 || service.serviceState == 4)
                        "
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="
                                    familyShopModal = true;
                                    hideActiveElements();
                                "
                                v-bind="attrs"
                                v-on="on"
                                fab
                                small
                            >
                                <font-awesome-icon icon="fa-solid fa-link" style="font-size: 1.1rem" />
                            </v-btn>
                        </template>
                        <span>Family Purchase Link</span>
                    </v-tooltip> -->

                    <custom-tooltip
                        :tooltipProps="{ bottom: true }"
                        v-if="
                            service.displayShop &&
                            !service.test &&
                            allowedPurchaseServiceStates.includes(service.serviceState)
                        "
                    >
                        <template v-slot:activator>
                            <v-btn
                                @click="
                                    openShopTab(service.funeralHomeName, service.funeralHomeId, true);
                                    hideActiveElements();
                                "
                                fab
                                small
                            >
                                <font-awesome-icon icon="fa-solid fa-link" style="font-size: 1.1rem" />
                            </v-btn>
                        </template>

                        <template v-slot:content>
                            <span>Family Purchase Link</span>
                        </template>
                    </custom-tooltip>

                    <!-- Funeral Program PDF -->
                    <!-- <v-tooltip attach="" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                :to="{ path: `/services/${service.slug}/add-pdf` }"
                                v-bind="attrs"
                                v-on="on"
                                fab
                                small
                            >
                                <font-awesome-icon icon="fa-regular fa-file-lines" style="font-size: 1.1rem" />
                            </v-btn>
                        </template>
                        <span>Add Funeral Program PDF</span>
                    </v-tooltip> -->
                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <v-btn :to="{ path: `/services/${service.slug}/add-pdf` }" fab small>
                                <font-awesome-icon icon="fa-regular fa-file-lines" style="font-size: 1.1rem" />
                            </v-btn>
                        </template>

                        <template v-slot:content>
                            <span>Add Funeral Program PDF</span>
                        </template>
                    </custom-tooltip>

                    <!-- View Stream Button -->
                    <!-- <v-tooltip attach="" bottom v-if="hasValidEvents">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="
                                    newVideoPlayer(service.slug);
                                    hideActiveElements();
                                "
                                v-bind="attrs"
                                v-on="on"
                                fab
                                small
                            >
                                <font-awesome-icon icon="fa-solid fa-play" style="font-size: 1.1rem" />
                            </v-btn>
                        </template>

                        <span>View Stream</span>
                    </v-tooltip> -->
                    <custom-tooltip :tooltipProps="{ bottom: true }" v-if="hasValidEvents">
                        <template v-slot:activator>
                            <v-btn
                                @click="
                                    newVideoPlayer(service.slug);
                                    hideActiveElements();
                                "
                                fab
                                small
                            >
                                <font-awesome-icon icon="fa-solid fa-play" style="font-size: 1.1rem" />
                            </v-btn>
                        </template>

                        <template v-slot:content>
                            <span>View Stream</span>
                        </template>
                    </custom-tooltip>

                    <!-- Disabled View Stream Button -->
                    <!-- <v-tooltip attach="" bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn target="_blank" v-bind="attrs" v-on="on" fab small class="disabled">
                                <font-awesome-icon icon="fa-solid fa-play" style="font-size: 1.1rem" />
                            </v-btn>
                        </template>

                        <span>No Active Events</span>
                    </v-tooltip> -->

                    <custom-tooltip :tooltipProps="{ bottom: true }" v-else>
                        <template v-slot:activator>
                            <v-btn target="_blank" fab small class="disabled">
                                <font-awesome-icon icon="fa-solid fa-play" style="font-size: 1.1rem" />
                            </v-btn>
                        </template>

                        <template v-slot:content>
                            <span>No Active Events</span>
                        </template>
                    </custom-tooltip>

                    <!-- Analytics Button -->
                    <!-- <v-tooltip attach="" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :to="{ path: `/analytics/${service.slug}` }" v-bind="attrs" v-on="on" fab small>
                                <font-awesome-icon icon="fa-solid fa-chart-simple" style="font-size: 1.1rem" />
                            </v-btn>
                        </template>
                        <span>View Analytics</span>
                    </v-tooltip> -->

                    <custom-tooltip :key="analyticsBtnRefreshKey" :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <v-btn :disabled="service.test" :to="{ path: `/analytics/${service.slug}` }" fab small>
                                <font-awesome-icon icon="fa-solid fa-chart-simple" style="font-size: 1.1rem" />
                            </v-btn>
                        </template>

                        <template v-slot:content>
                            <span v-if="service.test">Analytics not available on test services</span>
                            <span v-else>View Analytics</span>
                        </template>
                    </custom-tooltip>

                    <!-- Back Button -->
                    <!-- <v-tooltip attach="" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :to="{ name: 'Services' }" fab v-bind="attrs" v-on="on" small>
                                <font-awesome-icon icon="fa-solid fa-arrow-left" style="font-size: 1.1rem" />
                            </v-btn>
                        </template>
                        <span>Back To Services</span>
                    </v-tooltip> -->
                </div>
            </section>
            <!-- END: Service Header -->

            <!-- START: Service Editor -->
            <section class="service-editor-section">
                <!-- Event List -->
                <nav class="service-editor-section-nav">
                    <div
                        style="border-bottom: 3px solid #ff5252; text-overflow: ellipsis"
                        class="service-editor-section-entry"
                        :class="activeTab === -1 ? 'active' : ''"
                        @click="changeTab(-1)"
                    >
                        <!-- <span class="status-dot active"></span> -->
                        <h3 v-if="service.firstName != null">{{ pageTitle }}</h3>
                    </div>

                    <div v-for="(event, index) in events" :key="event.id">
                        <event-entry
                            v-if="event.eventType != 0 && event.eventType != 5"
                            :event="event"
                            :index="index"
                            :activeTab="activeTab"
                            :service="service"
                            @click="changeTab(index)"
                            @deleteEvent="event => deleteEvent(event)"
                        />
                    </div>

                    <div
                        class="service-editor-section-entry"
                        :class="activeTab === -2 ? 'active' : ''"
                        @click="changeTab(-2)"
                    >
                        <font-awesome-icon size="1x" icon="fa-solid fa-plus" />
                        <span>Add</span>
                    </div>

                    <div style="display: flex; flex-direction: column; flex-grow: 1">
                        <v-spacer></v-spacer>
                        <div
                            v-if="
                                (storyEvent && activeTab != storyEvent.index) ||
                                (tributeEvent && !storyEvent && activeTab != tributeEvent.index)
                            "
                            style="border-top: 3px solid #11578c"
                        ></div>
                        <div v-if="storyEvent != null">
                            <story-entry
                                class="story-entry"
                                :event="storyEvent"
                                :index="storyEvent.index"
                                :activeTab="activeTab"
                                :service="service"
                                :completed="storyHasRender"
                                :totalStories="totalStories"
                                @click="changeTab(storyEvent.index)"
                                @deleteEvent="event => deleteEvent(event)"
                            />
                        </div>

                        <div v-if="tributeEvent != null">
                            <tribute-entry
                                class="tribute-entry"
                                :event="tributeEvent"
                                :index="tributeEvent.index"
                                :activeTab="activeTab"
                                :service="service"
                                @click="changeTab(tributeEvent.index)"
                                @deleteEvent="deleteEvent(tributeEvent)"
                            />
                        </div>
                    </div>
                </nav>

                <!-- Event Content -->
                <div class="service-editor-section-content">
                    <div class="content-container fill-height">
                        <EditPanel
                            :tributeOnboarding="tributeOnboarding"
                            :loadingUsers="loadingUsers"
                            :users="users"
                            :type="activeTabData.type"
                            :event="activeTabData.event"
                            :service="service"
                            :service-events="events"
                            :currentTab="activeTab"
                            :funeralHomeSettings="funeralHomeSettings"
                            :tributeVideoRefreshKey="tributeVideoRefreshKey"
                            @refreshUserList="getUsers()"
                            @refreshUser="user => refreshUser(user)"
                            @updateEvent="event => updateEvent(event)"
                            @deleteEvent="event => deleteEvent(event)"
                            @createEvent="event => createEvent(event)"
                            @createTributeEvents="eventArr => createTributeEvents(eventArr)"
                            @updateService="service => updateService(service)"
                            @refresh="event => refreshData(event)"
                            @unhide-event="event => hideAction(event, false)"
                            @hide-event="event => hideAction(event, true)"
                            @refresh-photos="event => getPhotos(event)"
                            @refresh-tribute="event => getTributeVideo(event.id)"
                            @stories-count="val => (totalStories = val)"
                            @event-private-update="val => updateIsEventPrivate(val)"
                            @story-render="val => (storyHasRender = val)"
                        />
                    </div>
                </div>
            </section>
            <!-- END: Service Editor -->

            <!-- END: Service Embed Code -->

            <section class="service-embed-section">
                <div style="display: flex; flex-direction: column; align-items: end">
                    <v-btn-toggle
                        v-if="hasValidEvents && hasTributeEvents && tributeVideo.mainPhotoUrl"
                        active-class="active-toggle-btn"
                        dense
                        v-model="separatePlayer"
                        mandatory
                        rounded
                    >
                        <v-btn :value="false" small>Single</v-btn>
                        <v-btn :value="true" small>Dual</v-btn>
                    </v-btn-toggle>
                </div>

                <div
                    :class="{
                        'service-embed-section--two-col': separatePlayer && hasValidEvents && hasTributeEvents,
                    }"
                >
                    <embed-snippet
                        title="Service Embed Code"
                        v-if="embedCode && !service.private"
                        :embedCode="embedCode"
                        :hasValidEvents="displayServiceEmbed"
                    />

                    <embed-snippet
                        title="Tribute Embed Code"
                        v-if="
                            hasTributeEvents &&
                            tributeEmbedCode &&
                            tributeVideo.mainPhotoUrl &&
                            !service.private &&
                            separatePlayer
                        "
                        :embedCode="tributeEmbedCode"
                        :hasValidEvents="hasTributeEvents"
                    />
                </div>

                <embed-snippet
                    v-if="privateEmbedCode && service.private"
                    title="Private Viewing Link"
                    :embedCode="privateEmbedCode"
                    buttonText="Copy Private Link"
                    :hasValidEvents="hasValidEvents"
                />
                <embed-snippet
                    v-if="pdfEmbedCode"
                    title="PDF Embed Code"
                    :embedCode="pdfEmbedCode"
                    buttonText="Copy PDF Embed Code"
                    :hasValidEvents="hasValidEvents"
                />
            </section>

            <!-- START: Service Comments -->
            <!-- <ServiceComments v-if="$auth.role.includes('SuperAdmin')" :service="service" :events="events" /> -->

            <!-- END: Service Comments -->

            <!-- START: Service Analytics -->
            <ServiceBillingCharts
                :id="$route.params.id"
                :token="token"
                v-if="!service.test && hasValidEvents"
                :service="service"
                :key="serviceBillingKey"
            />
            <!-- END: Service Analytics -->

            <!-- Dialogs -->
            <v-dialog v-model="deleteDialog" width="300">
                <v-card class="align-stretch">
                    <v-card-title class="headline">Delete Event?</v-card-title>

                    <v-card-text v-if="deleteDialogType === 'admin'">
                        <p>Are you sure you want to delete?</p>

                        <p>
                            <strong>Deletable:</strong> <span>{{ deleteDiaglogContent.safe }}</span>
                        </p>

                        <p>
                            <strong>Amount:</strong> <span>{{ deleteDiaglogContent.amount }}</span>
                        </p>
                        <p>
                            <strong>Views:</strong> <span>{{ deleteDiaglogContent.views }}</span>
                        </p>
                        <p>
                            <strong>Consumed Seconds:</strong>
                            <span>{{ deleteDiaglogContent.consumedSeconds }}</span>
                        </p>
                    </v-card-text>

                    <v-card-text v-else> Are you sure you want to delete? </v-card-text>

                    <v-card-actions class="justify-center">
                        <div>
                            <v-btn color="gdarken-1" text @click="deleteDialog = false">Cancel</v-btn>
                            <v-btn color="red darken-1" text @click="deleteAction()">Delete</v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="hideDialog" width="300">
                <v-card>
                    <v-card-title class="headline">Delete Event?</v-card-title>

                    <v-card-text> This event cannot be deleted due to active billing. </v-card-text>

                    <v-card-text> Would you like to Hide this event instead? </v-card-text>

                    <v-card-actions class="justify-center">
                        <div>
                            <v-btn color="gdarken-1" text @click="hideDialog = false">Cancel</v-btn>
                            <v-btn color="warning" text @click="hideAction(deleteEventObject, true)">Hide</v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog @click:outside="$refs.inviteForm.reset()" max-width="600px" v-model="familyShopModal">
                <v-card>
                    <div class="pl-3 pr-3 decorated-title">
                        <v-card-title style="display: flex; justify-content: space-between">
                            <div class="text-container">Share DVD/USB Purchase Link</div>
                            <div class="img-container">
                                <img :src="require('@/assets/images/SW125_MemoryShare_Final-15.png')" alt="" />
                            </div>
                        </v-card-title>
                    </div>

                    <v-form ref="inviteForm" @submit.prevent="emailShopLink" v-model="formValid">
                        <div class="p-3">
                            <div class="email-group pt-0 p-2">
                                <!-- <v-text-field
                                @keydown.enter="emailShopLink"
                                class="t-input"
                                v-model="shopInviteEmail"
                                label="Email"
                            /> -->

                                <v-combobox
                                    v-model="ecomInviteList"
                                    hide-selected
                                    label="SMS / Email List"
                                    @input="handleInput"
                                    @blur="manualBlur"
                                    ref="emailCombo"
                                    multiple
                                    small-chips
                                    :deletable-chips="true"
                                    :counter="ecomInviteList.length > 0"
                                >
                                    <template v-slot:selection="data">
                                        <v-chip
                                            label
                                            small
                                            :color="invalidInvites.includes(data.item) ? 'error' : '#f8f8f8'"
                                            :key="JSON.stringify(data.item)"
                                            v-bind="data.attrs"
                                            :input-value="data.selected"
                                        >
                                            {{ data.item }}
                                            <font-awesome-icon
                                                @click.stop="removeEmailFromList(data)"
                                                class="ml-2 del-btn"
                                                icon="fa-regular fa-circle-x"
                                            />
                                        </v-chip>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <div>
                                                    Press <span class="entr-key">Enter</span> to add email or phone
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:counter="{ props }">
                                        <div v-if="props.value" class="email-counter">{{ props.value }} Recipients</div>
                                    </template>
                                </v-combobox>

                                <v-btn
                                    :disabled="ecomInviteList.length == 0"
                                    @click="emailShopLink"
                                    color="primary"
                                    class="btn"
                                >
                                    <font-awesome-icon icon="fa-solid fa-paper-plane-top" />
                                </v-btn>
                            </div>
                            <v-divider></v-divider>
                            <div class="text-center mb-2">
                                <i style="color: gray">Or copy / paste the link below</i>
                            </div>
                            <div class="link-displayed elevation-4">
                                <a
                                    style="overflow-wrap: break-word; word-break: break-all; white-space: normal"
                                    :href="familyShopLink"
                                    >{{ familyShopLink }}</a
                                >
                                <v-btn @click="copyShopLink" class="btn" dark>
                                    <font-awesome-icon icon="fa-regular fa-clone" />
                                </v-btn>
                            </div>
                        </div>
                    </v-form>

                    <!-- <v-btn dark>Copy</v-btn> -->

                    <!-- <v-spacer></v-spacer>
                <div class="p-3" style="display: flex; flex-direction: column">
                    <v-btn></v-btn>
                </div> -->
                </v-card>
            </v-dialog>

            <v-dialog v-model="pausedStatePurchaseModal" max-width="500">
                <v-card v-if="selectedEvent">
                    <v-card-title>End Stream?</v-card-title>
                    <v-card-text>
                        <strong>{{ selectedEvent.title }}</strong> is currently paused. Do you wish to end this stream?
                        <strong> You won't be able to stream to this event anymore.</strong>
                    </v-card-text>
                    <v-card-actions>
                        <div
                            style="width: 100%; gap: 12px"
                            class="d-flex flex-column flex-md-row justify-md-space-between"
                        >
                            <v-btn depressed @click="closePausedStatePurchaseModal">Cancel</v-btn>
                            <v-btn
                                :loading="loading"
                                :disabled="loading"
                                color="warning"
                                depressed
                                @click="endLiveStream(selectedEvent)"
                                >End Stream</v-btn
                            >
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="finishedStatePurchaseModal" max-width="500">
                <v-card v-if="selectedEvent">
                    <v-card-title>Submit Render?</v-card-title>
                    <v-card-text>
                        <strong>{{ selectedEvent.title }}</strong> has not been trimmed. Would you like to set a start
                        and stop time before ordering a DVD?</v-card-text
                    >
                    <v-card-actions>
                        <div style="width: 100%; gap: 12px" class="d-flex flex-column-reverse flex-md-column">
                            <div class="d-flex flex-column flex-md-row justify-md-end">
                                <v-btn text @click="closeFinishedStatePurchaseModal">Cancel</v-btn>
                            </div>

                            <div class="d-flex flex-column-reverse flex-md-row justify-md-space-between">
                                <v-btn
                                    class="mt-2"
                                    depressed
                                    :loading="loading"
                                    :disabled="loading"
                                    color="warning"
                                    @click="createRenderFromWowza(selectedEvent)"
                                    >No, submit render</v-btn
                                >
                                <v-btn
                                    :loading="loading"
                                    :disabled="loading"
                                    class="mt-2"
                                    depressed
                                    color="primary"
                                    @click="startEditing(selectedEvent)"
                                    >Yes, Edit Render</v-btn
                                >
                            </div>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </AppLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import EmbedSnippet from '@/components/Misc/EmbedSnippet.vue';
import EditPanel from '@/components/ManageService/EditPanel.vue';
import EventEntry from '@/components/ManageService/EventEntry.vue';
import Spinner from '@/components/ui/Spinner';
import ServiceBillingCharts from '@/components/charts/ServiceBillingCharts';
import UsersTable from '@/components/Tables/UsersTable';
import { isObjectEmpty } from '@/utilities/general.js';
import { embedCodeTypes } from '@/constants.js';
import ServiceComments from '@/components/ManageService/ServiceComments.vue';
import TributeEvent from '@/components/ManageService/Tribute/TributeEvent.vue';
import TributeEntry from '@/components/ManageService/Tribute/TributeEntry.vue';
import StoryEntry from '@/components/ManageService/Tribute/StoryEntry.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    name: 'ManageServiceRefactored',
    metaInfo() {
        return {
            title: !isObjectEmpty(this.service)
                ? `${this.service.firstName} ${this.service.lastName}`
                : 'Manage Services',
        };
    },
    data() {
        return {
            loading: true,
            service: {},
            events: [],
            token: '',
            activeTab: -1,
            activeTabData: {
                type: '',
                event: {},
            },
            embedCode: '',
            pdfEmbedCode: '',
            privateEmbedCode: 'd',
            tributeEmbedCode: '',
            hasTributeEvents: false,
            hideDialog: false,
            deleteDialog: false,
            deleteEventObject: null,
            deleteDiaglogContent: null,
            deleteDialogType: '',
            hasValidEvents: false,
            funeralHomeSettings: null,
            userTableModal: false,
            funeralHomeId: null,
            streamOwnerId: null,
            streamOwner: {},
            loadingUsers: false,
            users: [],
            displayShop: false,
            familyShopModal: false,
            shopInviteEmail: '',
            formValid: false,
            ecomInviteList: [],
            invalidInvites: [],
            tributeEvent: null,
            storyEvent: null,
            photosPageNum: 0,
            photosPageSize: 25,
            serviceBillingKey: 0,
            isMounted: false,
            validStandardEvents: [],
            validTributeEvents: [],
            separatePlayer: false,
            totalStories: 0,
            storyHasRender: false,
            analyticsBtnRefreshKey: 0,
            emailRules: [
                v => !!v || 'Email is required',
                v =>
                    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        v,
                    ) || 'E-mail must be valid',
            ],
            finishedStatePurchaseModal: false,
            pausedStatePurchaseModal: false,
            selectedEvent: null,
            allowedPurchaseServiceStates: [3, 4, 5, 7],
            shopTabType: '',
            tributeVideoRefreshKey: 0,
        };
    },
    methods: {
        /**
         * Gets all data related to the service and events.
         * Loads data into service and events data variables.
         *
         * @param id - Service ID
         */
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', [
            'updateTributeVideo',
            'updateTributeVideoPhotos',
            'updateTributeVideoSelectedPhotos',
            'resetTributeVideoState',
        ]),
        closePausedStatePurchaseModal() {
            this.pausedStatePurchaseModal = false;
            this.selectedEvent = null;
        },
        closeFinishedStatePurchaseModal() {
            this.finishedStatePurchaseModal = false;
            this.selectedEvent = null;
        },
        endLiveStream(event) {
            if (!event) return;

            this.loading = true;
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/live/stop/${event.liveStreamId}`)
                    .then(async response => {
                        this.pausedStatePurchaseModal = false;
                        await this.getData();

                        let refreshedEvent = this.events.find(e => e.id == event.id);
                        if (refreshedEvent && refreshedEvent.eventStatus === 3) {
                            this.changeTabToEvent(refreshedEvent);
                            this.selectedEvent = refreshedEvent;
                            this.finishedStatePurchaseModal = true;
                        }
                    })
                    .catch(error => {
                        this.showSnackbar({ message: 'Error Ending Live Stream', color: 'error' });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        createRenderFromWowza(event) {
            if (!event.id) {
                this.showSnackbar({ message: 'Error', color: 'error' });
                return;
            }

            this.loading = true;

            let data = {
                start: 0,
                stop: 0,
                autoAssignVm: true,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/events/create-render/${event.id}`, data)
                .then(async resp => {
                    this.closeFinishedStatePurchaseModal();
                    await this.getData();

                    let refreshedEvent = this.events.find(e => e.id == event.id);
                    this.changeTabToEvent(refreshedEvent);

                    const familyLinkModal = this.shopTabType === 'familyLinkModal';
                    this.openShopTab(this.service.funeralHomeName, this.service.funeralHomeId, familyLinkModal);
                    this.loading = false;
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error creating render', color: 'error' });
                });
        },
        startEditing(event) {
            if (!event.id) {
                this.showSnackbar({ message: 'Invalid event id', color: 'error' });
                return;
            }

            this.$router.push({ path: `/services/upload/${event.id}?uploaderType=edit` });
        },
        changeTabToEvent(event) {
            if (event) {
                let index = this.events.indexOf(event);
                this.changeTab(index);
                return true;
            }
            return false;
        },
        setDefaultEventTab() {
            let sortedEvents = [...this.events].sort((a, b) => b.eventStatus - a.eventStatus);

            let liveEvent = sortedEvents.find(e => e.eventStatus === 2);
            if (this.changeTabToEvent(liveEvent)) return;

            let firstActiveEvent = sortedEvents.find(e => e.time !== '0001-01-01T00:00:00');
            if (this.changeTabToEvent(firstActiveEvent)) return;

            let tributeVideoEvent = sortedEvents.find(e => e.eventType === 0);
            if (tributeVideoEvent && this.$store.state.tributeVideo?.mainPhotoId) {
                this.changeTabToEvent(tributeVideoEvent);
                return;
            }

            let storyEvent = sortedEvents.find(e => e.eventType === 5);
            if (storyEvent && this.totalStories > 0) {
                this.changeTabToEvent(storyEvent);
                return;
            }

            //If no active, default to the first event available
            let standardEvent = sortedEvents.find(e => e.eventType !== 0 && e.eventType !== 5);
            this.changeTabToEvent(standardEvent);
            return;
        },
        manualBlur() {
            this.$refs.emailCombo.blur();
        },
        removeEmailFromList(data) {
            this.ecomInviteList.splice(data.index, 1);
        },
        refreshUser(user) {
            var changedUser = this.users.find(u => u.id == user.id);

            changedUser.phoneNo = user.phoneNo;
            for (let i = 0; i < this.events.length; i++) {
                if (this.events[i].userId == user.id) {
                    this.events[i].user.phoneNo = user.phoneNo;
                    this.events[i].user.landLine = user.landLine;
                }
            }
        },
        getPhotos(event) {
            if (event == null || event.id <= 0) return;

            const id = event.id;
            this.updateTributeVideo({ loading: true });

            let pageNum = 0;
            let pageSize = this.photosOptions.pageSize ? this.photosOptions.pageSize : 24;

            if (this.tributeVideo.photos.length > 0) {
                pageNum = 0;
                pageSize = this.tributeVideo.photos.length;
            }

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })

                .get(
                    process.env.VUE_APP_API +
                        `/TributeVideoPhoto/event-photos/${id}?pageNumber=${pageNum}&pageSize=${pageSize}`,
                )
                .then(response => {
                    var photoList = response.data.photos;
                    var totalPhotos = response.data.total;

                    // //remove main photo from photo list
                    // if (response.data.photos.length > 0 && this.tributeVideo.mainPhotoId) {
                    //     const mainPhoto = photoList.find(x => x.id == this.tributeVideo.mainPhotoId);
                    //     if (mainPhoto) {
                    //         const index = photoList.indexOf(mainPhoto);
                    //         photoList.splice(index, 1);
                    //     }
                    // }

                    this.updateTributeVideoPhotos(photoList);
                    this.updateTributeVideo({
                        loading: false,
                        totalPhotos: totalPhotos,
                        photosOptions: { pageNum, pageSize },
                    });

                    // if (response.data.photos.length > 0) {
                    //     this.getTributeVideo(this.tributeEvent.id);
                    // }
                })
                .catch(error => {
                    [console.log(error, 'error')];
                });
        },
        isValidEmail(string) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(string);
        },
        isValidPhone(string) {
            const phonePattern = /^(?:(\d{3}\.?)(\d{3}\.?)(\d{4})|\d{10})$/;
            return phonePattern.test(string);
        },
        filterContacts(contacts) {
            const validEmails = [];
            const validPhoneNumbers = [];
            const invalid = [];

            contacts.forEach(contact => {
                if (this.isValidEmail(contact)) {
                    validEmails.push(contact);
                } else if (this.isValidPhone(contact)) {
                    validPhoneNumbers.push(contact);
                } else {
                    invalid.push(contact);
                }
            });

            return {
                validEmails,
                validPhoneNumbers,
                invalid,
            };
        },
        emailShopLink() {
            const filteredContacts = this.filterContacts(this.ecomInviteList);

            if (filteredContacts.invalid.length > 0) {
                this.invalidInvites = filteredContacts.invalid;
                this.showSnackbar({
                    message: `${filteredContacts.invalid.length} contacts are not a valid email or phone number.`,
                    color: 'error',
                });
                return;
            }

            let data = {
                emails: filteredContacts.validEmails.join(','),
                phoneNumbers: filteredContacts.validPhoneNumbers.join(','),
            };

            if (this.formValid) {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/services/ecom-invite-link/${this.service.id}`, data)
                    .then(response => {
                        this.showSnackbar({ message: 'Invitation Sent!' });
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        this.showSnackbar({ message: 'Error sending invitation', color: 'error' });
                    })
                    .finally(() => {
                        this.familyShopModal = false;
                        this.$refs.inviteForm.reset();
                    });
            } else {
                this.showSnackbar({ message: 'Error sending invitation', color: 'error' });
                return false;
            }
        },
        handleInput() {
            if (this.ecomInviteList.length > 10) {
                this.showSnackbar({
                    message: 'Please limit invites to a maximum of 10 at a time.',
                    color: 'error',
                });

                while (this.ecomInviteList.length > 10) {
                    this.ecomInviteList.pop();
                }
                return;
            }

            for (let i = 0; i < this.ecomInviteList.length; i++) {
                let num = this.ecomInviteList[i];
                if (this.isValidPhone(num) && !num.includes('.')) {
                    let formatted = this.formatPhoneNumber(num);
                    this.ecomInviteList[i] = formatted;
                }
            }

            const filteredContacts = this.filterContacts(this.ecomInviteList);

            if (filteredContacts.invalid.length > 0) {
                this.invalidInvites = filteredContacts.invalid;
            }
        },
        formatPhoneNumber(phoneNumber) {
            return phoneNumber.slice(0, 3) + '.' + phoneNumber.slice(3, 6) + '.' + phoneNumber.slice(6);
        },
        newVideoPlayer(slug) {
            if (
                this.validStandardEvents.length == 0 &&
                this.validTributeEvents.length > 0 &&
                this.service.separatePlayer
            ) {
                window.open(`${process.env.VUE_APP_VIDEO}/memoryshare/video/${slug}?tvl=true`, '_blank');
            } else if (this.service.private) {
                window.open(`${process.env.VUE_APP_VIDEO_PRIVATE}/memoryshare/video/${slug}`, '_blank');
            } else {
                window.open(`${process.env.VUE_APP_VIDEO}/memoryshare/video/${slug}`, '_blank');
            }
        },
        hideActiveElements() {
            if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
        },

        openShopTab(fhName, fhId, familyLinkModal) {
            if (familyLinkModal) {
                this.shopTabType = 'familyLinkModal';
            } else {
                this.shopTabType = null;
            }

            this.pausedStatePurchaseModal = false;
            this.finishedStatePurchaseModal = false;
            this.selectedEvent = null;

            let pausedEvent = this.events.find(e => e.eventStatus === 6);
            if (pausedEvent) {
                let index = this.events.indexOf(pausedEvent);
                this.changeTab(index);
                this.selectedEvent = pausedEvent;
                this.pausedStatePurchaseModal = true;
                return;
            }

            let finishedEvent = this.events.find(e => e.eventStatus === 3);
            if (finishedEvent) {
                let index = this.events.indexOf(finishedEvent);
                this.changeTab(index);
                this.selectedEvent = finishedEvent;
                this.finishedStatePurchaseModal = true;
                return;
            }

            if (familyLinkModal) {
                this.familyShopModal = true;
            } else {
                // this.hideActiveElements();
                let url = process.env.VUE_APP_ECOM + `/service/${this.service.slug}`;
                if (fhName) url += `/${encodeURI(fhName)}_${fhId * parseInt(process.env.VUE_APP_ECOM_FHID_KEY)}`;
                window.open(url, '_blank');
            }
            this.shopTabType = null;
        },
        copyShopLink() {
            const link = `${process.env.VUE_APP_ECOM}/service/${this.service.slug}`;
            navigator.clipboard.writeText(link);
            this.showSnackbar({ message: 'Copied to clipboard' });
        },
        getData: async function () {
            return new Promise((resolve, reject) => {
                this.loading = true;
                const slug = this.$route.params.slug;
                let id = '';

                this.$auth.getIdTokenClaims().then(async result => {
                    // Get Api Token
                    this.token = result.__raw;

                    const HEADERS = { headers: { Authorization: `Bearer ${this.token}` } };
                    const SERVICE_URL = process.env.VUE_APP_API + `/Services/slug/${slug}`;
                    const SERVICE_REQUEST = this.axios.create(HEADERS).get(SERVICE_URL);

                    await SERVICE_REQUEST.then(response => {
                        id = response.data.id;
                        this.service = response.data;
                        this.funeralHomeId = this.service.funeralHomeId;
                    });

                    this.getUsers();

                    // Construct Request

                    const EVENTS_URL = process.env.VUE_APP_API + `/events/list/${id}`;
                    const EMBED_CODE_URL = process.env.VUE_APP_API + `/embedcodes/service/${id}`;
                    const FUNERAL_HOME_SETTING_URL =
                        process.env.VUE_APP_API + `/funeralhomes/settings/${this.service.funeralHomeId}`;

                    const FUNERAL_HOME_URL = process.env.VUE_APP_API + `/funeralhomes/${this.service.funeralHomeId}`;

                    const SETTINGS_REQUEST = this.axios.create(HEADERS).get(FUNERAL_HOME_SETTING_URL);
                    const FH_REQUEST = this.axios.create(HEADERS).get(FUNERAL_HOME_URL);
                    const EVENT_REQUEST = this.axios.create(HEADERS).get(EVENTS_URL);
                    const EMBED_CODE_REQUEST = this.axios.create(HEADERS).get(EMBED_CODE_URL);

                    // Make Request
                    const [EVENT_RESPONSE, EMBED_CODE_RESPONSE, SETTINGS_RESPONSE, FH_RESPONSE] = await Promise.all([
                        EVENT_REQUEST.catch(error => {
                            this.$store.dispatch('block', false);
                            // this.$router.replace({ name: 'PathNotFound' });
                            reject(error);
                        }),

                        EMBED_CODE_REQUEST.catch(error => {
                            this.$store.dispatch('block', false);
                            // this.$router.replace({ name: 'PathNotFound' });
                            reject(error);
                        }),
                        SETTINGS_REQUEST.catch(error => {
                            this.$store.dispatch('block', false);
                            // this.$router.replace({ name: 'PathNotFound' });
                            reject(error);
                        }),

                        FH_REQUEST.catch(error => {
                            this.$store.dispatch('block', false);
                            // this.$router.replace({ name: 'PathNotFound' });
                            reject(error);
                        }),
                    ]);

                    const events = EVENT_RESPONSE.data;
                    this.events = events;

                    this.hasTributeEvents = this.events.some(ev => ev.eventType == 0 || ev.eventType == 5);

                    this.tributeEvent = events.find(e => e.eventType == 0);

                    if (this.tributeEvent) {
                        this.tributeEvent = {
                            ...this.tributeEvent,
                            index: this.events.indexOf(this.tributeEvent),
                        };
                        await this.getTributeVideo(this.tributeEvent.id);
                        if (this.tributeVideo.id != null) {
                            this.getPhotos(this.tributeEvent);
                        }
                        //     const mainPhoto = await this.getPhoto(this.tributeVideo.mainPhotoId);
                        // }
                    }
                    this.storyEvent = events.find(e => e.eventType == 5);

                    if (this.storyEvent) {
                        this.storyEvent = {
                            ...this.storyEvent,
                            index: this.events.indexOf(this.storyEvent),
                        };

                        this.totalStories = await this.getStories(this.storyEvent.id);
                    }

                    this.checkEvents();
                    if (!this.hasValidEvents || !this.hasTributeEvents) {
                        this.separatePlayer = false;
                        await this.updateTributeSeparatePlayer();
                    }

                    // // Since the Inital Tab is Service, we need to set its tab data
                    // this.activeTabData = {
                    //     type: 'serviceInfo',
                    //     event: this.service,
                    // };

                    // Get Embed Codes
                    const { data } = EMBED_CODE_RESPONSE;

                    const { embedCodeType } = data;

                    this.funeralHomeSettings = SETTINGS_RESPONSE.data;
                    this.funeralHome = FH_RESPONSE.data.funeralHome;

                    //OLD EMBED URL
                    // let embedURL = `${process.env.VUE_APP_BASE_URI}/memoryshare/videoplayer/${this.service.slug}`;
                    // this.privateEmbedCode = `${process.env.VUE_APP_BASE_URI}/services/view/${this.service.slug}`;

                    let embedURL = `${process.env.VUE_APP_VIDEO}/memoryshare/video/${this.service.slug}`;
                    let tributeEmbedURL;

                    if (this.hasTributeEvents) {
                        tributeEmbedURL = embedURL + '?tvl=true';
                    }

                    this.privateEmbedCode = `${process.env.VUE_APP_VIDEO_PRIVATE}/memoryshare/video/${this.service.slug}`;

                    let embedCode;
                    let tributeEmbedCode;

                    switch (embedCodeTypes[embedCodeType]) {
                        case 'Link':
                            embedCode = embedURL;
                            break;
                        case 'Iframe':
                            let processedHtmlWrapper = await this.generateSeoScriptTagIfNeeded(data);

                            processedHtmlWrapper = this.replaceEmbedPlaceholders(processedHtmlWrapper);
                            embedCode = processedHtmlWrapper;

                            if (this.hasTributeEvents) {
                                tributeEmbedCode = this.replaceEmbedPlaceholders(processedHtmlWrapper);
                            }

                            break;
                        default:
                            embedCode = embedURL;
                            console.warn('Default case of switch run');
                            break;
                    }

                    this.embedCode = embedCode;
                    if (this.hasTributeEvents) {
                        this.tributeEmbedCode = tributeEmbedCode;
                    }

                    // this.embedCode = `<iframe width="600" height="300" frameborder="0"  scrolling="no" src="https://develop.memoryshare.com/memoryshare/videoplayer/${this.service.slug}?width=600&height=300"></iframe>`;
                    this.pdfEmbedCode = await this.getPdfEmbedCode();
                    this.loading = false;
                    resolve();
                });
            });
        },
        replaceEmbedPlaceholders(embedCode) {
            var embedURL = `${process.env.VUE_APP_VIDEO}/memoryshare/video/${this.service.slug}`;
            var storyViewLink = `${process.env.VUE_APP_BASE_URI}/view-story/${this.$route.params.slug}`;
            const tributeContributeLink = this.tributeVideo.deeplink ? this.tributeVideo.deeplink : '';
            const storyContributeLink = this.tributeVideo.storyContributeLink
                ? this.tributeVideo.storyContributeLink
                : '';

            embedCode = this.replacePlaceholder(embedCode, embedURL, '{{src}}');
            embedCode = this.replacePlaceholder(embedCode, tributeContributeLink, '{{tributeContributeLink}}');
            embedCode = this.replacePlaceholder(embedCode, storyContributeLink, '{{storyContributeLink}}');

            embedCode = this.replacePlaceholder(embedCode, storyViewLink, '{{storyViewLink}}');
            embedCode = this.replacePlaceholder(embedCode, embedURL + '?tvl=true', '{{tributeViewLink}}');
            return embedCode;
        },
        async getStories(eventId) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Story/settings/${eventId}`)
                .then(resp => {
                    return resp.data.totalTributeStories;
                })
                .catch(error => {
                    if (error.response && error.response.status == 404) {
                        return null;
                    } else {
                        console.log(error, 'tribute story settings not found');
                    }
                });
        },
        replacePlaceholder(wrapper, url, placeHolder) {
            if (!wrapper) return url;
            return wrapper.replace(placeHolder, url);
        },
        async generateSeoScriptTagIfNeeded(data) {
            if (data.htmlWrapper && data.htmlWrapper.includes('{{seoScript}}')) {
                let scriptTag = await this.generateEmbedScriptTag();
                return data.htmlWrapper.replace('{{seoScript}}', scriptTag);
            }
            return data.htmlWrapper;
        },
        async getContentUrlDuration() {
            var availableEvents = this.events.filter(x => x.convertedVideo != null || x.liveURL != null);
            var selectedEvent = null;
            var contentDuration = 0;
            let contentURL = null;

            if (availableEvents.length > 0) {
                //Sort by live = true
                availableEvents.sort((a, b) => {
                    // If a is live and b is not, a should come first
                    if (a.live && !b.live) {
                        return -1;
                    }
                    // If b is live and a is not, b should come first
                    if (b.live && !a.live) {
                        return 1;
                    }
                    // If both are live or neither are, maintain existing order
                    return 0;
                });

                selectedEvent = availableEvents[0];
                if (selectedEvent) {
                    contentURL = selectedEvent.convertedVideo ? selectedEvent.convertedVideo : selectedEvent.liveURL;
                }

                if (contentURL && contentDuration == 0) {
                    try {
                        if (contentURL.includes('.m3u8')) {
                            contentDuration = await this.getM3U8Duration(contentURL);
                        } else {
                            contentDuration = await this.getVideoDuration(contentURL);
                        }
                    } catch (error) {
                        console.log(error, 'Error fetching video duration');
                        return contentDuration;
                    }
                }
                return contentDuration;
            }
        },
        getVideoDuration(url) {
            return new Promise((resolve, reject) => {
                let video = document.createElement('video');
                video.addEventListener(
                    'loadedmetadata',
                    function () {
                        resolve(video.duration);
                    },
                    false,
                );
                video.addEventListener(
                    'error',
                    function () {
                        reject('Error loading video');
                    },
                    false,
                );
                video.src = url;
            });
        },
        async getM3U8Duration(m3u8Url) {
            try {
                // Fetch the M3U8 file
                const response = await fetch(m3u8Url);
                const m3u8Content = await response.text();

                // Check if it's a master playlist and find the media playlist
                if (m3u8Content.includes('#EXT-X-STREAM-INF')) {
                    const lines = m3u8Content.split('\n');
                    for (let line of lines) {
                        if (!line.startsWith('#') && line.trim() !== '') {
                            // Construct the full URL for the media playlist
                            const mediaPlaylistUrl = new URL(line, m3u8Url).href;
                            // Fetch and calculate duration of the media playlist
                            return await getM3U8Duration(mediaPlaylistUrl);
                        }
                    }
                }

                // Assuming now it's a media playlist
                const mediaLines = m3u8Content.split('\n');
                let totalDuration = 0;
                mediaLines.forEach(line => {
                    if (line.startsWith('#EXTINF:')) {
                        const durationMatch = line.match(/#EXTINF:([\d.]+)/);
                        if (durationMatch && durationMatch[1]) {
                            totalDuration += parseFloat(durationMatch[1]);
                        }
                    }
                });

                return totalDuration;
            } catch (error) {
                console.error('Error fetching or parsing M3U8 file:', error);
                return null;
            }
        },
        formatDurationISO8601(durationInSeconds) {
            // Convert the duration to an integer (rounding down)
            let totalSeconds = Math.floor(durationInSeconds);

            // Calculate hours, minutes, and seconds
            let hours = Math.floor(totalSeconds / 3600);
            let minutes = Math.floor((totalSeconds % 3600) / 60);
            let seconds = totalSeconds % 60;

            // Construct the ISO 8601 duration string
            let duration = 'P';
            if (hours > 0) {
                duration += `${hours}H`;
            }
            if (minutes > 0 || hours > 0) {
                duration += `${minutes}M`;
            }
            if (seconds > 0 || (hours === 0 && minutes === 0)) {
                duration += `${seconds}S`;
            }

            return duration;
        },
        getServiceThumbnail() {
            if (!this.events || !this.service) {
                return '';
            }

            let eventWithThumb = this.events.find(x => x.videoThumbnail != null);
            if (eventWithThumb) {
                return eventWithThumb.videoThumbnail;
            }
            return `${process.env.VUE_APP_VIDEO}/memoryshare/video/${this.service.slug}`;
        },
        formatSchemaDate(dateString) {
            const date = new Date(dateString);
            // Get the timezone offset in minutes and convert it to hours and minutes
            const offset = -date.getTimezoneOffset();
            const offsetHours = Math.floor(Math.abs(offset / 60));
            const offsetMinutes = Math.abs(offset % 60);

            // Format the date and time
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Months are zero indexed
            const day = date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            // Pad single digits with leading zeros
            const monthStr = month < 10 ? '0' + month : month.toString();
            const dayStr = day < 10 ? '0' + day : day.toString();
            const hoursStr = hours < 10 ? '0' + hours : hours.toString();
            const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
            const secondsStr = seconds < 10 ? '0' + seconds : seconds.toString();
            const offsetHoursStr = offsetHours < 10 ? '0' + offsetHours : offsetHours.toString();
            const offsetMinutesStr = offsetMinutes < 10 ? '0' + offsetMinutes : offsetMinutes.toString();

            // Combine into final string with the formatted timezone offset
            return (
                `${year}-${monthStr}-${dayStr}T${hoursStr}:${minutesStr}:${secondsStr}` +
                `${offset >= 0 ? '+' : '-'}${offsetHoursStr}:${offsetMinutesStr}`
            );
        },
        async generateEmbedScriptTag() {
            const duration = await this.getContentUrlDuration();
            const formattedDuration = this.formatDurationISO8601(duration);

            const thumb = this.getServiceThumbnail();
            const live = this.events.find(x => x.live == true);

            let description = `Watch: ${this.service.firstName} ${this.service.lastName} of ${this.funeralHome.city}, ${this.funeralHome.state} ${this.service.firstName} ${this.service.lastName}'s Funeral`;

            let formattedUploadDate = this.formatSchemaDate(new Date());
            if (live) {
                formattedUploadDate = this.formatSchemaDate(live.createDate);
            } else {
                formattedUploadDate = this.formatSchemaDate(this.events[0].createDate);
            }

            var schemaObj = {
                '@context': 'https://schema.org',
                '@type': 'VideoObject',
                contentURL: `${process.env.VUE_APP_VIDEO}/memoryshare/video/${this.service.slug}`,
                description: description,
                duration: formattedDuration,
                embedUrl: `${process.env.VUE_APP_VIDEO}/memoryshare/video/${this.service.slug}`,
                name: `${this.service.firstName} ${this.service.lastName}'s`,
                thumbnailUrl: thumb,
                uploadDate: formattedUploadDate,
                publication: [],
            };

            if (live && live.time) {
                let formattedStartTime = this.formatSchemaDate(live.time);

                let endTime = new Date(live.time);
                endTime.setHours(endTime.getHours() + 1);
                endTime.setMinutes(endTime.getMinutes());

                let formattedEndTime = this.formatSchemaDate(endTime);

                schemaObj.publication[0] = {
                    '@type': 'BroadcastEvent',
                    isLiveBroadcast: true,
                    startDate: formattedStartTime,
                    endDate: formattedEndTime,
                };
            }

            let schemaString = JSON.stringify(schemaObj);
            var scriptString = `<` + `script  type="application/ld+json">{${schemaString}}</` + `script>`;

            return scriptString;
        },
        getPhoto(id) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideoPhoto/${id}`)
                .then(response => {
                    // this.$store.dispatch('updateTributeVideo', {
                    //     ...this.tributeVideo,
                    //     mainPhotoUrl: response.data.url,
                    // });
                    this.updateTributeVideo({
                        ...this.tributeVideo,
                        mainPhotoUrl: response.data.url,
                    });
                    return response;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        getTributeVideo(id) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideo/get-tribute-by-event/${id}`)
                .then(response => {
                    this.tributeVideo = {
                        ...response.data,
                        firstName: this.service.firstName,
                        lastName: this.service.lastName,
                        birthDate: this.service.birthDate,
                        deathDate: this.service.deathDate,
                    };

                    this.separatePlayer = this.tributeVideo.separatePlayer;

                    // if (this.separatePlayer) {
                    //     this.embedType = 'dual';
                    // } else {
                    //     this.embedType = 'single';
                    // }

                    // this.$store.dispatch('updateTributeVideo', this.tributeVideo);
                    this.updateTributeVideo(this.tributeVideo);
                    this.tributeVideoRefreshKey++;
                    return this.tributeVideo;
                })
                .catch(error => {
                    // console.log(error, 'error');
                    return null;
                });
        },
        updateIsEventPrivate(val) {
            if (val.id) {
                let event = this.events.find(e => e.id == val.id);

                if (event) {
                    event.private = val.private;

                    if (event.eventType == 5 && this.storyEvent) {
                        this.storyEvent.private = val.private;
                    }
                }
            }
        },
        /**
         * Function to Refresh data, and switch to tab
         */
        refreshData: function (event) {
            let eventIndex = this.events.findIndex(entry => entry.id === event.id) || 0;
            this.getData().then(() => {
                this.changeTab(eventIndex);
            });
        },
        /**
         * Changes the Left Hand Editor Tab
         * @param index - the index of the tab chosen.
         */
        changeTab: function (index) {
            // Update Index
            this.activeTab = index;

            let type = '';
            let event = {};

            switch (index) {
                case -1: {
                    type = 'serviceInfo';
                    event = this.service;
                    break;
                }
                case -2: {
                    type = 'newEvent';
                    event = {};
                    break;
                }
                default: {
                    type = 'eventInfo';
                    event = this.events[index];
                }
            }

            // Update Active Tab Data
            this.activeTabData = { type, event };
        },
        /**
         * Currently, the title of an event comes back from the api as
         * 'firstName lastName's Funeral'. We need to remove the first
         * and last name for visual purposes.
         */
        cleanTitle: function (title) {
            let cleaned = title.replace(`${this.service.firstName} ${this.service.lastName}'s `, '');
            return cleaned;
        },
        /**
         * Updates Service
         */
        updateService: function (service) {
            this.$store.dispatch('block', true);

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(`${process.env.VUE_APP_API}/Services/${this.service.id}`, service)
                .then(response => {
                    this.getData().then(() => {
                        this.$store.dispatch('block', false);
                    });
                    this.analyticsBtnRefreshKey++;
                })
                .catch(error => {});
        },

        /**
         * Updates an Event Given an Event Object. Since this is a PUT
         * Operation, the keys must match the keys of the event object
         */
        updateEvent: function (event) {
            this.$store.dispatch('block', true);
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(process.env.VUE_APP_API + `/Events/${event.id}`, event.data)
                .then(response => {
                    this.getData().then(() => {
                        let eventIndex = this.events.findIndex(entry => entry.id === event.id);
                        this.changeTab(eventIndex);
                        this.$store.dispatch('block', false);
                    });
                })
                .catch(error => {
                    this.$store.dispatch('block', false);
                });
        },
        /**
         * Deletes the Selected Event
         */
        deleteEvent: async function (event) {
            // Set the object to be deleted
            this.deleteEventObject = event;

            if (event.eventType == 0) {
                this.separatePlayer = false;
                await this.updateTributeSeparatePlayer();
            }

            // If the Event is a DEFAULT event type
            if (event.eventType === 0 || event.eventType === 1 || event.eventType === 2 || event.eventType === 3) {
                // Check if it has a time, cause then it will be an active event
                if (event.time === '0001-01-01T00:00:00') {
                    this.deleteAction();
                    return;
                }
            }

            // Check if event is safe to delete, then show confirmation
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Events/safetodelete/${event.id}`)
                .then(response => {
                    // If super admin show warning regardless
                    if (this.$auth.role.includes('SuperAdmin')) {
                        this.deleteDialogType = 'admin';
                        this.deleteDiaglogContent = response.data;
                        this.deleteDialog = true;

                        return;
                    }

                    // If Regular User, then do a check.
                    if (response.data.safe === 'yes') {
                        this.deleteDialogType = '';
                        this.deleteDialog = true;
                    } else {
                        this.deleteDialogType = 'hide';
                        this.hideDialog = true;
                    }
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Event Cannot Be Deleted!', color: 'error' });
                });
        },
        /**
         * Actual function to delete the event.
         */
        deleteAction: function () {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .delete(process.env.VUE_APP_API + `/Events/${this.deleteEventObject.id}`)
                .then(response => {
                    const event = this.deleteEventObject;
                    this.getData().then(() => {
                        this.changeTab(-1);

                        if (event.eventType == 0) {
                            this.resetTributeVideoState();
                        }
                    });

                    this.deleteEventObject = null;
                    this.deleteDialog = false;
                })
                .catch(error => {
                    this.deleteEventObject = null;
                });
        },

        /**
         * Hides an Event
         */
        hideAction: function (event, action) {
            this.$store.dispatch('block', true);

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/Events/hide/${event.id}?hide=${action}`)
                .then(response => {
                    this.$store.dispatch('block', false);

                    // this.getData().then(() => {
                    //     this.changeTab(-1);
                    // });
                    this.refreshData(event);

                    this.hideDialog = false;
                    this.deleteEventObject = null;
                })
                .catch(error => {
                    this.$store.dispatch('block', false);
                    this.deleteEventObject = null;
                });
        },
        /**
         * Creates an Event
         */
        createEvent: function (event) {
            this.$store.dispatch('block', true);

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + '/Events', event.data)
                .then(response => {
                    const newEventId = response.data.id;

                    this.getData().then(() => {
                        let eventIndex = this.events.findIndex(entry => entry.id === newEventId);

                        this.changeTab(eventIndex);
                        this.$store.dispatch('block', false);
                    });
                })
                .catch(error => {
                    this.$store.dispatch('block', false);
                });
        },
        createTributeEvents(events) {
            this.$store.dispatch('block', true);

            const axiosPromises = events.map(event => {
                return this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + '/Events', event);
            });

            Promise.all(axiosPromises)
                .then(responses => {
                    const tributeEvent = responses.find(x => x.data.eventType == 0);

                    return this.getData().then(() => {
                        let eventIndex = this.events.findIndex(entry => entry.id === tributeEvent.data.id);
                        this.changeTab(eventIndex);
                        this.$store.dispatch('block', false);
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.$store.dispatch('block', false);
                });
        },
        /**
         * Get PDF for this Service
         */
        getPdfEmbedCode() {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/pdf/${this.service.id}`)
                .then(({ data }) => {
                    if (data) {
                        const { imageLink, titleText, pdfLink } = data;
                        return `<center>
                            <a href="${pdfLink}"
                            target="_blank">
                                <br>
                                <img style="border:0;width:220px;box-shadow: 10px 10px 10px rgba(0,0,0,0.3);margin-bottom: 24px;margin-top: 30px;"
                                    src="${imageLink}"/>
                                <br>
                                <div style=";border: 1px solid #565656b3;max-width: 400px;width: fit-content;padding: 11px 31px;margin-bottom: 40px;font-size: 18px;margin-top: 1px;background: rgba(255,255,255,0.5);font-family: 'Lato';font-weight: 600; color:#333;text-decoration-line:none">
                                    ${titleText}
                                </div>
                            </a>
                        </center>`;
                    }
                })
                .catch(error => {
                    return '';
                });
        },

        /**
         * Checks to see if there are any valid events. This is used to decided wether or not to
         * Dispay the embed code
         */
        checkEvents() {
            this.hasValidEvents = this.service.serviceState === 0 ? false : true;
            this.validStandardEvents = this.events.filter(x => x.eventType > 0 && x.eventType < 5 && x.eventStatus > 0);
            this.validTributeEvents = this.events.filter(
                x => (x.eventType == 0 || x.eventType == 5) && x.convertedVideo != null,
            );
        },
        getUsers() {
            this.loadingUsers = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/users?funeralHomeId=${this.funeralHomeId}`)
                .then(response => {
                    this.users = response.data;

                    this.loadingUsers = false;
                })
                .catch(error => {
                    console.log('error', error);
                    this.loadingUsers = false;
                });
        },
        async updateTributeSeparatePlayer() {
            if (this.tributeVideo.id) {
                let data = {
                    separatePlayer: this.separatePlayer,
                };

                return this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/TributeVideo/separate-player/${this.tributeVideo.id}`, data)
                    .then(resp => {})
                    .catch(err => {
                        console.log(err, 'error');
                        this.showSnackbar({
                            message: 'Error updating upload deadline',
                            color: 'error',
                        });
                    });
            }
        },
    },
    watch: {
        events: function () {
            this.checkEvents();
        },
        service: function () {
            this.checkEvents();
        },
        separatePlayer() {
            this.updateTributeSeparatePlayer();
        },
    },
    components: {
        EditPanel,
        EventEntry,
        Spinner,
        ServiceBillingCharts,
        EmbedSnippet,
        UsersTable,
        ServiceComments,
        TributeEvent,
        TributeEntry,
        StoryEntry,
        CustomTooltip,
    },
    computed: {
        pageTitle() {
            return `${this.service.firstName} ${this.service.lastName}`;
        },
        initials() {
            if (this.streamOwner.firstName && this.streamOwner.lastName) {
                return `${this.streamOwner.firstName[0]}${this.streamOwner.lastName[0]}`.toUpperCase();
            }
        },
        familyShopLink() {
            return `${process.env.VUE_APP_ECOM}/service/${this.service.slug}`;
        },
        tributeVideo: {
            get() {
                return this.$store.state.tributeVideo;
            },
            set(value) {
                this.updateTributeVideo(value);
            },
        },
        photosOptions() {
            return this.$store.state.tributeVideo.photosOptions;
        },
        tributeOnboarding() {
            return (
                // this.$store.state.tributeVideo.totalPhotos + this.$store.state.tributeVideo.incomingUploadCount <
                // this.$store.state.tributeVideo.minPhotos
                this.$store.state.tributeVideo.id <= 0
            );
        },
        displayServiceEmbed() {
            if (
                this.hasValidEvents ||
                (this.hasTributeEvents && this.tributeEmbedCode && this.tributeVideo.mainPhotoUrl)
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    async mounted() {
        this.isMounted = true;
        this.$store.dispatch('block', true);

        await this.getData();

        // Since the Inital Tab is Service, we need to set its tab data
        this.activeTabData = {
            type: 'serviceInfo',
            event: this.service,
        };
        this.$store.dispatch('block', false);
        this.loading = false;
        this.checkEvents();

        if (this.$route.params.event) {
            // Convert event to number
            const parsed = parseInt(this.$route.params.event);

            // Check if event is number
            if (isNaN(parsed)) {
                return;
            }

            // Check if number is in range to see if the event index was passed
            if (parsed > 0 && parsed <= this.events.length) {
                this.changeTab(parsed - 1);
            }

            // else lets check if the event is an id in the events
            this.events.forEach((event, index) => {
                if (event.id === parsed) {
                    this.changeTab(index);
                }
            });
        } else {
            this.setDefaultEventTab();
        }

        // this.getUsers();
    },
    beforeDestroy() {
        this.resetTributeVideoState();
    },
    sockets: {
        async NotifyLive(data) {
            if (!this.loading && data.serviceId === this.service.id && data.eventStatus != 4) {
                if (data.eventStatus == 6 || data.eventStatus == 3) {
                    this.serviceBillingKey++;
                }

                await this.getData();

                if (data.eventStatus == 2) {
                    let liveEvent = this.events.find(e => e.id === data.id);
                    if (this.changeTabToEvent(liveEvent)) return;
                }

                this.changeTab(this.activeTab);
            }
        },
        async NotifyUpload(data) {
            //if (this.tributeEvent.id && data.id == this.tributeEvent.id) {
            // if (this.tributeOnboarding && this.tributeVideo.id) {
            // }
            //this.getPhotos(this.tributeEvent.id);
            //}

            if (this.storyEvent && this.storyEvent.id && data.id == this.storyEvent.id && !this.loading) {
                this.totalStories = await this.getStories(this.storyEvent.id);
            }
        },
    },
};
</script>

<style lang="scss">
// .service-page-container {
//     container-type: inline-size;
// }

.settings-link {
    border: none;
    padding: 5px;
    border-radius: 5px;
    transition: 0.3s;
    margin-left: -5px;
}
.settings-link:hover {
    background-color: #ff743d;
    text-decoration: none;
    transition: 0.3s;
}
.email-counter {
    width: 100%;
    font-size: 0.8rem;
    color: gray;
}
.entr-key {
    border: 1px solid black;
    border-radius: 5px;
    padding: 3px;
    font-size: 0.8rem;
}
.del-btn {
    cursor: pointer;
    transition: 0.2s;
}
.del-btn:hover {
    color: black;
    transition: 0.2s;
}
.email-group {
    display: flex;
    align-items: center;

    .btn {
        margin-left: 10px;
    }
}
.link-displayed {
    border: 2px solid #ff530d;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
        margin-left: 10px;
    }
}
.decorated-title {
    color: white !important;
    background-color: #0d3d60 !important;

    // background-image: url('../../assets/images/SW125_MemoryShare_Final-15.png');
    // background-position: 95% 50%;
    // background-size: 10%;
    .text-container {
        width: 80%;
        word-break: break-word;
    }
    .img-container {
        width: 20%;
        max-width: 50px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}

.vdatetime {
    border-bottom: 1px solid #949494;
    padding-bottom: 0.5rem;

    .vdatetime-input {
        width: 100%;
    }
}

.service-header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header {
        color: white;
        margin: 0;
    }

    .button-container {
        display: flex;
        gap: 0.5rem;
    }

    width: 100%;
}

.service-editor-section {
    background: white;
    border-radius: 4px;
    margin-top: 2rem;
    box-shadow: 0 0 1rem rgb(155, 155, 155);
    display: flex;
    width: 100%;
    max-width: 100% !important;

    &-nav {
        display: flex;
        flex-direction: column;
        background: #f8f8f8;
        border-radius: 4px 0 0 4px;
        padding: 3rem 0;
        min-width: 250px;
        max-width: 250px;
    }

    &-content {
        max-width: calc(100% - 250px) !important;
        padding: 3rem 4rem;
        flex: 1;
        // overflow-x: hidden;

        .content-container {
            height: 100%;
            max-width: 100%;
            // max-width: 400px;
        }
    }
    .tribute-entry:hover {
        background-color: #90a6b6;
    }
    .tribute-entry.active {
        background-color: #ff530d;
    }
    .tribute-entry.active::before {
        background: #ff530d;
    }

    .story-entry:hover {
        background-color: #90a6b6;
    }

    .story-entry.active.active {
        background: #ff530d;
    }
    .story-entry.active::before {
        background: #ff530d;
    }

    &-entry {
        padding: 0 1.5rem;
        height: 80px;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.2s all;
        size: 1.2rem;
        gap: 0.7rem;

        .status-dot {
            height: 0.75rem;
            width: 0.75rem;
            background: rgb(198, 198, 198);
            border-radius: 50%;
        }

        .status-dot.active {
            background: #43ea11;
        }

        h3 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 250;
        }

        .entry-title {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 300px;
        }

        .live-container {
            display: flex;
            align-items: center;

            span {
                font-size: 0.75rem;
                font-weight: bold;
                z-index: 50;
            }
        }
    }
    &-entry.active,
    &-entry:hover {
        background: #90a6b6;
        color: white;
        font-weight: bold;
    }

    &-entry.active::before {
        content: '';
        height: 80px;
        width: 50px;
        position: absolute;
        right: -25px;
        top: 0;
        border-radius: 50%;
        background: #90a6b6;
    }
}

.service-embed-section {
    margin-top: 2rem;
    width: 100%;

    &--two-col {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 2rem;
    }

    .active-toggle-btn {
        background: #ff530d !important;
        color: white !important;
        border: none !important;
    }
}

// BLOB =======================================================================
.blob {
    display: inline-block;
    border-radius: 50%;
    margin: 0 0.5rem;
    height: 15px;
    width: 15px;
    vertical-align: middle;
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.dash-container-service-editor {
    background-image: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/3db295f3-24bb-4347-8d2e-c58abfeb92aa.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: 0 -18rem, bottom right;
    background-size: auto, 30em;
    background-attachment: scroll, fixed;
    padding: 5rem 3rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 4rem;
    transition: 0.1s linear;

    &.menu-showing {
        margin-left: 18rem !important;
    }
}

// //Container query to match mobile layout if container @ mobile width
// @container (max-width: 768px) {
//     .service-editor-section-entry .delete-btn {
//         position: absolute;
//         right: 10px;
//     }

//     .email-group {
//         flex-direction: column;
//         .btn {
//             width: 100%;
//             margin-top: 10px;
//             margin-left: 0;
//         }
//         .t-input {
//             width: 100%;
//         }
//     }
//     .link-displayed {
//         flex-direction: column;
//         .btn {
//             width: 100%;
//             margin-top: 10px;
//             margin-left: 0;
//         }
//     }
//     .service-editor-section {
//         flex-direction: column;
//     }

//     .service-editor-section-content {
//         padding: 2rem !important;
//     }

//     .service-editor-section-nav {
//         width: 100%;
//         max-width: 100%;
//     }
//     .service-editor-section-entry.active::before {
//         display: none;
//     }

//     .service-header-section {
//         flex-direction: column;
//         gap: 1rem;
//         text-align: center;
//     }
//     .event-title-container {
//         flex-direction: column-reverse;
//         align-items: flex-start !important;
//     }

//     .service-embed-section {
//         grid-template-columns: 1fr !important;
//         overflow: hidden;
//     }
// }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .email-group {
        flex-direction: column;
        .btn {
            width: 100%;
            margin-top: 10px;
            margin-left: 0;
        }
        .t-input {
            width: 100%;
        }
    }
    .link-displayed {
        flex-direction: column;
        .btn {
            width: 100%;
            margin-top: 10px;
            margin-left: 0;
        }
    }
    .service-editor-section {
        flex-direction: column;
    }

    .service-editor-section-content {
        padding: 2rem !important;
        max-width: 100% !important;
    }

    .service-editor-section-nav {
        width: 100%;
        max-width: 100%;
    }
    .service-editor-section-entry.active::before {
        display: none;
    }

    .service-header-section {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
    }
    .event-title-container {
        flex-direction: column-reverse;
        align-items: flex-start !important;
    }

    .service-embed-section {
        grid-template-columns: 1fr !important;
        overflow: hidden;
    }
}
</style>
